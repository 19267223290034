import React, { useEffect, useState } from "react";

import "./Loader.scss";

const Loader = () => {
  const [loadingMessage, setLoadingMessage] = useState(
    "Filling up the King Bear's tank with honey..."
  );

  const loadingSentences = [
    "Summoning the Demon Bear...",
    "Preparing the Pirate Bear's outfit...",
    "Sharpening the Lich Bear's claws...",
    "Polishing the Sage Bear's sword...",
    "Feeding the King Bear's pet dragon...",
    "Teaching the Cowboy Bear new battle moves...",
    "Counting the Moneybag Bear's treasure...",
    "Charging up the Demon Bear's magical powers...",
    "Grooming the Pirate Bear's luxurious fur...",
    "Training the Lich Bear's loyal army...",
    "Searching for the perfect potion for the Sage Bear...",
    "Filling up the King Bear's tank with honey...",
    "Designing a new battle helmet for the Cowboy Bear...",
    "Recruiting new bear warriors to join the Moneybag Bear's quest...",
    "Consulting with the Demon Bear's wise old advisor...",
    "Polishing the Pirate Bear's golden throne...",
    "Digging for buried treasure with the Lich Bear...",
    "Building a new castle for the Sage Bear...",
    "Sailing the high seas with the King Bear's pirate crew...",
    "Battling fierce enemies alongside the Cowboy Bear...",
    "Crafting new weapons for the Moneybag Bear's arsenal...",
    "Exploring mysterious ruins with the Demon Bear...",
    "Counting the Pirate Bear's stacks of gold coins...",
    "Feeding the Lich Bear's pet grizzly...",
    "Unleashing the Sage Bear's dark magic upon foes...",
    "Taking the King Bear's horse for a run...",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * loadingSentences.length);
      setLoadingMessage(loadingSentences[randomIndex]);
    }, 1000);
    return () => clearInterval(interval);
  }, [loadingSentences]);

  return (
    <div className="loading-page">
      <div className="loading-sentence">{loadingMessage}</div>
    </div>
  );
};

export default Loader;
