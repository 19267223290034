import React from "react";

import "./AuctionSale.scss";
// Assets
import PirateBearBig from "../../../assets/media/GIF/pirateBearHome.gif";

const AuctionSale = () => {
  return (
    <div className="auctionsale">
      <div className="auctionsale-left">
        <div className="auctionsale-left-container">
          <div className="littleheader-wrapper">AUCTION SALE</div>
          <div className="auction-title">
            <span className="auction-title">AUCTION:</span> TBA
          </div>
          <div className="auction-directions-wrapper">
            <p>
              Claim your place among the elite as 5000 powerful Barbearians
              become available, with 3000 up for grabs in an epic public auction
              - the top half of winning bidders will secure all powerful Battle
              Bears, while the bottom half will acquire Noobie Bears and $GOLD.
              After the dust settles, the remaining 2000 will be offered to
              whitelist members at the lowest winning bid price, with a 50-50
              chance of receiving a fearsome Fighter Bear or a Noobie Bear.
              Seize this opportunity to dominate the battlefield and etch your
              name among the legends!
            </p>
          </div>
          <div className="sidequests-button-wrapper placeholder-button not-available">
            <div
              // onClick={() => navigate("/allowlist-sale")}
              className="sidequests-button"
            >
              SALE PAGE
            </div>
          </div>
        </div>
      </div>
      <div className="auctionsale-right">
        <img src={PirateBearBig} alt="" />
      </div>
    </div>
  );
};

export default AuctionSale;
