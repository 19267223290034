import React, { useEffect, useState, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import useSound from "use-sound";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../components/context/context";

import ChainedBear from "../../../assets/sound/chainedBear.mp3";
import BattleReadyRoar from "../../../assets/sound/battleReadyBear.mp3";
import { ReactComponent as EclipseBack } from "../../../assets/media/icons/ellipseBack.svg";
import { ReactComponent as EclipseFill } from "../../../assets/media/icons/ellipseFill.svg";
import NoobieBear from "../../../assets/media/icons/noobieBear.png";
import BattleReadyBear from "../../../assets/media/icons/battleReadyBear.png";
import LostBear from "../../../assets/media/icons/lostBear.png";
import HostageBearChains from "../../../assets/media/icons/hostageBearChains.png";
import MainBears from "../../../assets/media/images/mainBears.mp4";
import BattleMissionAnimation from "../../../assets/media/images/battleMission.mp4";
import TrainAnimation from "../../../assets/media/images/trainNoobies.mp4";
import crackEffectOne from "../../../assets/media/images/crackEffectOne.png";
import crackEffectTwo from "../../../assets/media/images/crackEffectTwo.png";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

import "./TheBears.scss";

const cards = [
  "/assets/card-1.png",
  "/assets/card-2.png",
  "/assets/card-3.png",
  "/assets/card-4.png",
  "/assets/card-5.png",
];

const bearInfo = [
  "Noobie Bears require training with a Fighter Bear to leave the castle (your wallet) and embark on missions.",
  "Send your Fighter Bears to missions, earn $GOLD, train your Noobie Bears, rescue Hostage Bears and more.",
  "Fighter Bears taken hostage in unsuccessful missions can be rescued by allies in your army.",
  "Hostage Bears that are not rescued in time are executed by the enemy. They will RIP in the graveyard (your wallet) forever.",
];

gsap.registerPlugin(SplitText, ScrollTrigger);
const TheBears = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 950px)" });
  const navigate = useNavigate();
  const [playChained] = useSound(ChainedBear, { volume: 0.2 });
  const [playBattleReady] = useSound(BattleReadyRoar, { volume: 0.2 });

  // States
  const [bearName, setBearName] = useState("NOOBIE BEAR");
  const [bearImage, setBearImage] = useState(cards[0]);
  const [fillPercentage, setFillPercentage] = useState(0);
  const [clickedNoobie, setClickedNoobie] = useState(true);
  const [clickedBattleReady, setClickedBattleReady] = useState(false);
  const [clickedHostage, setClickedHostage] = useState(false);
  const [clickedLost, setClickedLost] = useState(false);
  const [bearInfoText, setBearInfoText] = useState(bearInfo[0]);

  const { isSoundEnabled } = useContext(MainContext);

  useEffect(() => {
    gsap.set(".hostagebear-chain1", { x: 200, opacity: 0 });
    gsap.set(".hostagebear-chain2", { x: 100, opacity: 0 });
  }, []);

  const theBearsClickHandler = (bear, imgIndex, bearNumber, fillNumber) => {
    if (bearName !== bear) {
      switch (bearNumber) {
        case 1:
          setClickedNoobie(true);
          setClickedBattleReady(false);
          setClickedHostage(false);
          setClickedLost(false);
          gsap.to(".hostagebear-chain1", { x: 200, ease: "back.out(2)" });
          gsap.to(".hostagebear-chain2", { x: 100, ease: "back.out(2)" });
          gsap.to(".bear-card-wrapper img", { filter: "saturate(100%)" });

          setBearInfoText(bearInfo[0]);
          break;

        case 2:
          setClickedNoobie(false);
          setClickedBattleReady(true);
          setClickedHostage(false);
          setClickedLost(false);

          isSoundEnabled && playBattleReady();

          gsap.to(".hostagebear-chain1", { x: 200, ease: "back.out(2)" });
          gsap.to(".hostagebear-chain2", { x: 100, opacity: 0 });
          gsap.to(".bear-card-wrapper img", { filter: "saturate(100%)" });

          setBearInfoText(bearInfo[1]);
          break;

        case 3:
          setClickedNoobie(false);
          setClickedBattleReady(false);
          setClickedHostage(true);
          setClickedLost(false);

          isSoundEnabled && playChained();

          gsap.to([".hostagebear-chain1", ".hostagebear-chain2"], {
            stagger: 0.2,
            opacity: 1,
            x: 0,
            ease: "back.out(1)",
          });
          gsap.to(".bear-card-wrapper img", { filter: "saturate(20%)" });

          setBearInfoText(bearInfo[2]);
          break;

        case 4:
          setClickedNoobie(false);
          setClickedBattleReady(false);
          setClickedHostage(false);
          setClickedLost(true);
          gsap.to(".hostagebear-chain1", { x: 200, ease: "back.out(2)" });
          gsap.to(".hostagebear-chain2", { x: 100, ease: "back.out(2)" });
          gsap.to(".bear-card-wrapper img", { filter: "saturate(100%)" });

          setBearInfoText(bearInfo[3]);
          break;

        default:
          break;
      }

      gsap.to(".bear-card-wrapper", { y: 15, filter: "blur(5px)" });

      setTimeout(() => {
        setBearName(bear);
        setBearImage(cards[imgIndex]);
        gsap.to(".bear-card-wrapper", { y: 0, filter: "blur(0px)" });
      }, 250);
    }

    return;
  };

  useEffect(() => {
    const missionTitle = new SplitText(".mission-info-title", {
      type: "chars",
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".thebears",
        start: "top+=40% +=40%",
        end: "bottom-=20% +=50%",
      },
    });

    tl.fromTo(
      missionTitle.chars,
      { opacity: 0 },
      {
        opacity: 1,
        stagger: 0.01,
        ease: "power4.out",
      }
    )
      .to([".crack-1", ".crack-2"], {
        clipPath: "inset(0% 0% 0% 0%)",
        delay: 1.25,
      })
      .fromTo(
        [".train", ".battle", ".mint"],
        { x: 600, y: -300, opacity: 0, scale: 1.15, y: -150, duration: 0.25 },
        {
          x: 0,
          y: 0,
          duration: 1,
          stagger: 0.1,
          ease: "back.out(2)",
          scale: 1,
          opacity: 1,
        },
        "-=1"
      )
      .to([".crack-1", ".crack-2"], {
        clipPath: "inset(0% 0% 100% 0%)",
        opacity: 0,
        delay: 0.75,
      })
      .to([".crack-1", ".crack-2"], { display: "none" });
  }, []);

  return (
    <div className="thebears">
      <div className="title-wrapper">
        <p>THE BEARS</p>
      </div>
      <div className="bottom-section-wrapper">
        <div className="left-side">
          <div className="bear-info-wrapper">
            <div className="bear-header-wrapper">
              <div className="bear-name">{bearName}</div>
              <div className="bearians-info">{bearInfoText}</div>
            </div>
            {isTablet && (
              <>
                <div className="bear-header-wrapper">
                  <div className="bearians-info">{bearInfo[1]}</div>
                </div>
                <div className="bear-header-wrapper">
                  <div className="bearians-info">{bearInfo[2]}</div>
                </div>
                <div className="bear-header-wrapper">
                  <div className="bearians-info">{bearInfo[3]}</div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="right-side">
          <div className="bear-card-wrapper">
            <img src={bearImage} alt="" />
            <img
              src={HostageBearChains}
              className="hostagebear-chain1"
              alt=""
            />
            <img
              src={HostageBearChains}
              className="hostagebear-chain2"
              alt=""
            />
          </div>
          <div className="ellipse-wrapper">
            <EclipseBack className="ellipse-back" />
            <EclipseFill className="ellipse-fill" />
            <div
              onClick={() => theBearsClickHandler("NOOBIE BEAR", 0, 1)}
              className="noobie-bear-button-wrapper"
            >
              <div className="bearbutton-left-side">
                <img src={NoobieBear} alt="" />
              </div>
              <div
                className={
                  clickedNoobie
                    ? "bearbutton-right-side active"
                    : "bearbutton-right-side"
                }
              >
                NOOBIE BEAR
              </div>
            </div>
            <div
              onClick={() => theBearsClickHandler("FIGHTER BEAR", 2, 2)}
              className="battleready-bear-button-wrapper"
            >
              <div className="bearbutton-left-side">
                <img src={BattleReadyBear} alt="" />
              </div>
              <div
                className={
                  clickedBattleReady
                    ? "bearbutton-right-side active"
                    : "bearbutton-right-side"
                }
              >
                FIGHTER BEAR
              </div>
            </div>
            <div
              onClick={() => theBearsClickHandler("HOSTAGE BEAR", 4, 3)}
              className="hostage-bear-button-wrapper"
            >
              <div className="bearbutton-left-side">
                <img src={BattleReadyBear} alt="" />
                <img src={HostageBearChains} className="chain-1" alt="" />
                <img src={HostageBearChains} className="chain-2" alt="" />
              </div>
              <div
                className={
                  clickedHostage
                    ? "bearbutton-right-side active"
                    : "bearbutton-right-side"
                }
              >
                HOSTAGE BEAR
              </div>
            </div>
            <div
              onClick={() => theBearsClickHandler("MARTYR BEAR", 3, 4)}
              className="lost-bear-button-wrapper"
            >
              <div className="bearbutton-left-side">
                <img src={LostBear} alt="" />
              </div>
              <div
                className={
                  clickedLost
                    ? "bearbutton-right-side active"
                    : "bearbutton-right-side"
                }
              >
                MARTYR BEAR
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mission-infos-container">
        <div className="mission-info-title">
          GROW YOUR ARMY AND DOMINATE THE BATTLEFIELD.
        </div>
        <div className="missions-description-card-wrapper">
          {" "}
          <img src={crackEffectOne} className="crack crack-1" alt="" />
          <img src={crackEffectTwo} className="crack crack-2" alt="" />
          <div className="description-card train">
            <div className="description-gradient" />
            <video
              autoPlay
              muted
              loop
              playsInline
              className="description-card-video"
              src={TrainAnimation}
            />
            <div className="description-card-title">
              Train and send your Barbearians on epic missions.
            </div>
          </div>
          <div className="description-card battle">
            <video
              autoPlay
              muted
              loop
              playsInline
              className="description-card-video"
              src={BattleMissionAnimation}
            />
            <div className="description-gradient" />
            <div className="description-card-title">
              Reap $GOLD from of your hard-fought victories.
            </div>
          </div>
          <div className="description-card mint">
            <video loop autoPlay muted playsInline src={MainBears} />
            <div className="description-gradient" />
            <div className="description-card-title">
              Use $GOLD to mint more Barbearians and acquire exclusive power-ups
              to aid in missions.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheBears;
