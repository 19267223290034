import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Error.scss";

import { MainContext } from "../../components/context/context";
import Footer from "../../components/Footer/Footer";

// Assets
import goldenBearError from "../../assets/media/images/goldenBearHover.webm";
import errorSpeechBubble from "../../assets/media/images/404errorbubble.png";
import HallButton from "../../components/GotoHallButton/HallButton";
const Error = () => {
  const navigate = useNavigate();
  const { setShowNavBar } = useContext(MainContext);

  useEffect(() => {
    document.title = "Bear&Bull Game • 404";
    setShowNavBar(true);
  }, []);

  return (
    <>
      <HallButton />
      <div className="error-page">
        <div className="error-bear">
          <video
            className="error-bear-video"
            src={goldenBearError}
            muted
            loop
            autoPlay
          />
          <img className="speech-bubble" src={errorSpeechBubble} alt="" />
        </div>
        <div onClick={() => navigate("/home")} className="gotohome-button">
          GO TO HOME
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Error;
