import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { notifyError } from "./utils/functions";
const ProtectedRoute = ({ isConnected, children }) => {
  const user_token = localStorage.getItem("bearToken");

  if (!isConnected && !user_token) {
    notifyError("You must connect your wallet to access this page.");

    return <Navigate to="/home" />;
  }
  return children;
};

export default ProtectedRoute;
