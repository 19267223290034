import React, { useEffect, useContext, useState, Suspense } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import "./Missions.scss";
import { MainContext } from "../../components/context/context";
import HallButton from "../../components/GotoHallButton/HallButton";
import Footer from "../../components/Footer/Footer";

// Assets
import { ReactComponent as RightIcon } from "../../assets/media/icons/pixelRight.svg";
import CardBottomBackground from "../../assets/media/images/cardBottomAsset.png";
// import TrainNoobies from "../../assets/media/images/trainNoobies.mp4";
// import RescueMissionAnimation from "../../assets/media/images/rescueMission.mp4";
// import BattleMission from "../../assets/media/images/battleMission.mp4";
import TrainNoobiesStatic from "../../assets/media/images/trainNoobiesStatic.png";
import BattleMissionStatic from "../../assets/media/images/battleMissionStatic.png";
import RescueMissionStatic from "../../assets/media/images/rescueMissionStatic.png";
import TrainNoobiesGif from "../../assets/media/images/trainNoobies.gif";

const TrainNoobiesGifLazy = React.lazy(() =>
  import("./Lazy/LazyElements").then((module) => ({
    default: module.LazyLeftBackground,
  }))
);

const BattleMissionGifLazy = React.lazy(() =>
  import("./Lazy/LazyElements").then((module) => ({
    default: module.LazyCenterBackground,
  }))
);

const RescueMissionGifLazy = React.lazy(() =>
  import("./Lazy/LazyElements").then((module) => ({
    default: module.LazyRightBackground,
  }))
);

const Missions = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const { setShowNavBar } = useContext(MainContext);

  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState({
    left: false,
    center: false,
    right: false,
  });

  const handleMouseEnter = (card) => {
    setIsHovered((prevState) => ({
      ...prevState,
      [card]: true,
    }));
  };

  const handleMouseLeave = (card) => {
    setIsHovered((prevState) => ({
      ...prevState,
      [card]: false,
    }));
  };

  useEffect(() => {
    document.title = "Bear&Bull Game • Missions";
    setShowNavBar(true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <HallButton />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="missions"
      >
        <div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ duration: 1, ease: "easeInOut" }}
          onClick={() => navigate("/graveyard")}
          className={
            scrolled ? "graveyard-wrapper scrolled" : "graveyard-wrapper"
          }
        >
          <div className="graveyard">
            <div className="graveyard-text">GRAVEYARD</div>
            <RightIcon width={50} />
          </div>
        </div>
        <div className="missions-title">EMBARK ON A MISSION</div>
        <div className="whitelist-quests-cards-wrapper">
          <motion.div
            initial={{ opacity: 0, x: isMobile ? 0 : 250 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.75, ease: "easeInOut" }}
            className="whitelist-quests-card"
            onMouseEnter={() => handleMouseEnter("left")}
            onMouseLeave={() => handleMouseLeave("left")}
          >
            {/* <video
              playsInline
              autoPlay={false}
              muted
              loop
              src={TrainNoobies}
              className="leftcard-background"
            /> */}

            {isHovered.left ? (
              <Suspense
                fallback={
                  <img
                    className="leftcard-background"
                    src={TrainNoobiesStatic}
                    alt=""
                  />
                }
              >
                <TrainNoobiesGifLazy />
              </Suspense>
            ) : (
              <img
                className="leftcard-background"
                src={TrainNoobiesStatic}
                alt=""
              />
            )}

            <div className="leftcard-gradient" />
            <div className="whitelist-quests-card-texts-wrapper">
              <div className="whitelist-quests-card-title">TRAINING</div>
              <div className="whitelist-quests-card-description">
                Stake your Noobie Bears with Fighter Bears.
              </div>
            </div>
            <div className="whitelist-quests-card-button-wrapper">
              <img
                src={CardBottomBackground}
                className="card-bottom-background"
                alt=""
              />
              <div className="whitelist-quests-card-button">COMING SOON</div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: isMobile ? 1 : 0.75 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.7, delay: 0.25, ease: "easeInOut" }}
            className="whitelist-quests-card card-middle"
            onMouseEnter={() => handleMouseEnter("center")}
            onMouseLeave={() => handleMouseLeave("center")}
          >
            <div className="middlecard-gradient" />
            {/* <video
              className="middlecard-background"
              src={BattleMission}
              playsInline
              muted
              loop
              autoPlay
            /> */}

            {isHovered.center ? (
              <Suspense
                fallback={
                  <img
                    className="middlecard-background"
                    src={BattleMissionStatic}
                    alt=""
                  />
                }
              >
                <BattleMissionGifLazy />
              </Suspense>
            ) : (
              <img
                className="middlecard-background"
                src={BattleMissionStatic}
                alt=""
              />
            )}

            <div className="whitelist-quests-card-texts-wrapper">
              <div className="whitelist-quests-card-title">BATTLE</div>
              <div className="whitelist-quests-card-description">
                Stake your favorite FB to earn some GOLDs.
              </div>
            </div>
            <div className="whitelist-quests-card-button-wrapper">
              <img
                src={CardBottomBackground}
                className="card-bottom-background middle"
                alt=""
              />
              <div className="whitelist-quests-card-button">COMING SOON</div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: isMobile ? 0 : -250 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.75, ease: "easeInOut" }}
            className="whitelist-quests-card"
            onMouseEnter={() => handleMouseEnter("right")}
            onMouseLeave={() => handleMouseLeave("right")}
          >
            <div className="rightcard-gradient" />
            {/* <video
              playsInline
              autoPlay
              muted
              loop
              className="rightcard-background"
              src={RescueMissionAnimation}
            /> */}

            {isHovered.right ? (
              <Suspense
                fallback={
                  <img
                    className="rightcard-background"
                    src={RescueMissionStatic}
                    alt=""
                  />
                }
              >
                <RescueMissionGifLazy />
              </Suspense>
            ) : (
              <img
                className="rightcard-background"
                src={RescueMissionStatic}
                alt=""
              />
            )}
            <div className="whitelist-quests-card-texts-wrapper">
              <div className="whitelist-quests-card-title">RESCUE</div>
              <div className="whitelist-quests-card-description">
                Rescue your Hostage Bears from dying with FB and earn GOLD.
              </div>
            </div>
            <div className="whitelist-quests-card-button-wrapper">
              <img
                src={CardBottomBackground}
                className="card-bottom-background"
                alt=""
              />
              <div className="whitelist-quests-card-button">COMING SOON</div>
            </div>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default Missions;
