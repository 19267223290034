import React, { useEffect, useState, useRef, useContext } from "react";
import { useCountUp } from "react-countup";
import { MainContext } from "../../components/context/context";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { notifyError, notifySuccess } from "../../utils/functions";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

import "./Home.scss";
import TheBears from "./Sections/TheBears";
import TheEcosystem from "./Sections/TheEcosystem";
import RoadMap from "./Sections/RoadMap";
import AuctionSale from "./Sections/AuctionSale";
import Footer from "../../components/Footer/Footer";
import HallButton from "../../components/GotoHallButton/HallButton";

// Assets
import { ReactComponent as LineSVG } from "../../assets/media/Home/bearCountLine.svg";
import TacklingBull from "../../assets/media/Home/tacklingBull.png";
import BearDark from "../../assets/media/Intro/Hall/bearKingDark.webm";

// Cards array
const cards = [
  "/assets/card-1.png",
  "/assets/card-2.png",
  "/assets/card-3.png",
  "/assets/card-4.png",
  "/assets/card-5.png",
];

gsap.registerPlugin(SplitText, ScrollTrigger);

const Home = () => {
  const navigate = useNavigate();
  const mm = gsap.matchMedia();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const { setShowNavBar } = useContext(MainContext);
  const countUpRef = useRef(null);

  // States
  const [shuffledCards, setShuffledCards] = useState([]);
  const [isCountPlayed, setIsCountPlayed] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState("");

  const { start } = useCountUp({
    ref: countUpRef,
    start: !isMobile ? 2500 : 0,
    end: 5000,
    duration: 1,
  });

  const playCount = () => {
    if (!isCountPlayed) {
      start();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowNavBar(true);
    document.title = "Bear&Bull Game • Home";
    document.body.style.height = "auto";
    document.body.style.overflow = "scroll";

    // Shuffle little cards(Description section)
    let cardsShuffledArr = cards
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    setShuffledCards(cardsShuffledArr);

    // Animations
    const tl = gsap.timeline();
    tl.to(
      ".barbearians-title-wrapper h1",
      {
        stagger: 0.05,
        scale: 0.9,
        opacity: 1,
        delay: 1.6,
        duration: 1,
        ease: "sine.inOut",
      },
      "-=1.5"
    ).to(
      [".subtitle-wrapper", ".cta-btn-wrapper"],
      {
        opacity: 1,
        stagger: 0.4,
      },
      "-=1"
    );

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".description-section",
        start: "top 100%",
        end: "bottom top",
        scrub: 1,
      },
    });

    // setTimeout(() => {
    //   tl2
    //     .to([".card-1", ".card-5"], { y: -175 })
    //     .to([".card-2", ".card-4"], { y: -75 }, "<");
    // }, 1);

    setTimeout(() => {
      tl2
        .to(".card-1", {
          x: 1100,
        })
        .to(".card-2", { x: 900 }, "<")
        .to(".card-3", { x: 700 }, "<")
        .to(".card-4", { x: 500 }, "<")
        .to(".card-5", { x: 300 }, "<");
    }, 1);

    // make work tl3 and tl4 for larger devices and if the user enters with mobile it wouldn't play animation

    // Bear count trigger - 1
    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".bear-count-section",
        start: "top-=250px center+=250px",
        end: "bottom top",
        onEnter: () => playCount(),
        onLeave: () => setIsCountPlayed(true),
        onEnterBack: () => setIsCountPlayed(true),
      },
    });

    // Bear count trigger - 2
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: ".bear-count-section",
        start: "top 40%",
        end: "bottom top",
        // markers: true,
      },
    });

    mm.add("(min-width: 480px)", () => {
      tl3
        .to(".ten-thousand", {
          opacity: 1,
          ease: "power4.out",
        })
        .to(".bear-count", { opacity: 1, ease: "power4.out", delay: 1 });

      tl4
        .from(".bear-count-subtitle span", {
          stagger: 0.1,
          opacity: 0,
          y: 50,
          ease: "power4.out",
          duration: 1.3,
        })
        .from(
          ".tackling-bull",
          {
            opacity: 0,
            rotate: 45,
            y: 25,
            ease: "back.out",
          },
          "-=1"
        )
        .to(".tackling-bull", { y: -13.5 }, "-=1")
        .from(
          [".paragraph-one", ".paragraph-two"],
          {
            opacity: 0,
            stagger: 0.2,
            y: 40,
          },
          "<"
        );
    });

    gsap.to(".linear-gradient", {
      autoAlpha: 0,
      scrollTrigger: {
        scrub: true,
        trigger: ".bear-count-section",
        start: "bottom-=150 bottom",
        end: "bottom center+=250",
      },
    });
  }, []);

  // Subscribe e-mail functions
  const handleEmailChange = (e) => {
    setSubscribeEmail(e.target.value);
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleSubmit = async () => {
    if (isValidEmail(subscribeEmail)) {
      const api_response = await fetch(
        process.env.REACT_APP_BASEURL + "users/waitlist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email_address: subscribeEmail }),
        }
      );
      const result = await api_response.json();
      if (result.status) {
        setSubscribeEmail("");
        notifySuccess("Thank you for subscribing!");
      }
    } else {
      notifyError("Please enter a valid email address.");
    }
  };

  return (
    <>
      <HallButton />
      <div className="home">
        <div className="landing-page">
          <div className="homelanding-pic" />
          <div className="bear-king-wrapper">
            <video
              playsInline
              autoPlay
              loop
              muted
              onClick={() => navigate("/hall")}
              src={BearDark}
              alt=""
            />
          </div>
          <div className="barbearians-title-wrapper">
            <h1>BARBEARIANS</h1>
          </div>
          <div className="subtitle-wrapper">
            <h3>REDEFINING THE BEAR MARKET MICRO & MACRO ECONOMICS</h3>
          </div>
          <div className="cta-btn-wrapper">
            <div className="home-email-input-wrapper">
              <input
                onChange={handleEmailChange}
                placeholder="Subscribe to news"
                className="email-input"
                type="email"
              />
            </div>
            <div onClick={handleSubmit} className="home-cta-btn">
              REQUEST CARRIER PIGEON
            </div>
          </div>
        </div>

        <div className="description-section">
          <div className="description-title">
            <p>
              A{" "}
              <span className="orange">
                FIRST OF ITS KIND BEAR(MARKET) PROJECT
              </span>{" "}
              with novel gamifications.
            </p>
            <p>
              This project is focused on quality, community and most importantly
              the bear market survivors.
            </p>
          </div>
          <div className="bear-cards-wrapper">
            <div className="bear-cards">
              {shuffledCards.map((imageSrc, index) => (
                <img
                  key={index}
                  className={`card-${index + 1}`}
                  src={imageSrc}
                  alt=""
                />
              ))}
            </div>
          </div>
          <div className="linear-gradient" />
        </div>

        <div className="bear-count-section">
          <div className="bear-count-wrapper">
            <div className="inner-title">
              <div className="bear-count">BEAR COUNT</div>
              {!isMobile ? (
                <div ref={countUpRef} className="ten-thousand"></div>
              ) : (
                <div className="ten-thousand ten-thousand-2">5000</div>
              )}
            </div>
          </div>
          <div className="bear-count-subtitle">
            <span className="only-tenthousand">
              ”Amidst the chaos of the bear market,
            </span>
            <span className="will-be-pulled">
              the first 5000 Barbearians take up arms to defend their kingdom
              and etch their names into history.”
            </span>
          </div>
          <div className="bear-count-paragraph-wrapper">
            <div className="bear-animation-wrapper">
              <div className="line-svg">
                <LineSVG className="line" />
              </div>
              <div className="tackling-bull">
                <img src={TacklingBull} alt="" />
              </div>
              <div className="paragraph-wrapper">
                <p className="paragraph-one">
                  Get ready to embark on an epic journey as 5000 Barbearians are
                  set to be minted through the highly anticipated Whitelist and
                  Public sale. For the bravest of warriors, the remaining 5000
                  Barbearians can be acquired using in-game $GOLD, earned
                  through fearless battle and conquests beyond measure. Join the
                  ranks of the mightiest fighters and claim your place among the
                  legends!
                </p>
              </div>
            </div>
          </div>
        </div>

        <TheBears />
        <TheEcosystem />
        <RoadMap />
        <AuctionSale />

        <Footer />
      </div>
    </>
  );
};

export default Home;
