import React from "react";
import { nanoid } from "nanoid";

import "./Cart.scss";

// Assets
import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";
import BearCoin from "../../assets/media/icons/BearCoinSmall.png";

const Cart = ({
  GOLD_PRICE,
  showCart,
  setShowCart,
  marketCart,
  removeFromCart,
  setMarketCart,
}) => {
  return (
    <div className={showCart ? "cart-component active" : "cart-component"}>
      <div className="cart-title">
        {marketCart.length !== 0 ? (
          <p>YOUR CART({marketCart.length})</p>
        ) : (
          <p>YOUR CART</p>
        )}
        <div className="cart-close-btn" onClick={() => setShowCart(false)}>
          <span>CLOSE</span>
        </div>
      </div>

      {marketCart.length !== 0 && (
        <div onClick={() => setMarketCart([])} className="clear-cart-btn">
          CLEAR CART
        </div>
      )}

      <div className="carts-wrapper">
        {marketCart.length === 0 ? (
          <div className="cart-empty">
            <p>Your cart is empty.</p>
          </div>
        ) : (
          marketCart.map((item) => (
            <div className="cart-item" key={nanoid()}>
              <div className="cart-item-image">
                <img src={item.image} alt={item.name} />
              </div>
              <div className="cart-item-details">
                <p>#{item.name}</p>
              </div>
              <div
                title="Remove from cart"
                onClick={() => removeFromCart(item.name)}
                className="cart-remove-btn"
              >
                <CloseIcon />
              </div>
            </div>
          ))
        )}{" "}
      </div>

      {marketCart.length !== 0 && (
        <div className="buynow-container">
          <div className="cart-gold">
            <div className="daily-rewards-left">
              <img src={BearCoin} alt="" />
            </div>
            <div className="daily-rewards-right">
              <div className="daily-rewards-info">1200 GOLD</div>
              <div className="daily-rewards-title">TOTAL:</div>
            </div>
          </div>

          <div className="cart-buy-btn">
            <span>BUY NOW</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
