import React, { useEffect, useState } from "react";
import gsap from "gsap";
import { useMediaQuery } from "react-responsive";
import { useBearsData } from "../../../../components/context/bearsContext";

import "./InfoPopup.scss";

// Assets
import { ReactComponent as CloseIcon } from "../../../../assets/media/icons/CancelIcon.svg";

const InfoPopup = ({ isOngoingBattle, isOngoingTraining, isOngoingRescue }) => {
  const { setIsInfoPopupOpen } = useBearsData();

  const tl = gsap.timeline();

  const [modalActiveTab, setModalActiveTab] = useState("training");
  const [isTrainingButtonDisabled, setIsTrainingButtonDisabled] =
    useState(false);
  const [isBattleButtonDisabled, setIsBattleButtonDisabled] = useState(false);
  const [isRescueButtonDisabled, setIsRescueButtonDisabled] = useState(false);

  useEffect(() => {
    switch (true) {
      case isOngoingTraining:
        setModalActiveTab("training-tutorial");
        setIsBattleButtonDisabled(true);
        setIsRescueButtonDisabled(true);
        break;
      case isOngoingBattle:
        setModalActiveTab("battle-tutorial");
        setIsTrainingButtonDisabled(true);
        setIsRescueButtonDisabled(true);
        break;
      case isOngoingRescue:
        setModalActiveTab("rescue-tutorial");
        setIsTrainingButtonDisabled(true);
        setIsBattleButtonDisabled(true);
        break;
      default:
        setModalActiveTab("training-tutorial");
        setIsTrainingButtonDisabled(false);
        setIsBattleButtonDisabled(false);
        setIsRescueButtonDisabled(false);
        break;
    }
  }, []);

  const clickTabHandler = (tab) => {
    setModalActiveTab(tab);
  };

  const closeHandler = () => {
    tl.to(".dashboard-info-popup", {
      duration: 0.3,
      opacity: 0,
    })
      .to(".dashboard-info-popup-back", {
        duration: 0.1,
        opacity: 0,
      })
      .then(() => setIsInfoPopupOpen(false));
  };

  return (
    <>
      <div onClick={closeHandler} className="dashboard-info-popup-back" />
      <div className="dashboard-info-popup">
        <div onClick={closeHandler} className="faqmodal-header-close">
          <CloseIcon />
        </div>
        <div className="faqmodal-header">
          <div className="modal-tabs">
            <div
              style={{
                display: isTrainingButtonDisabled && "none",
                fontSize:
                  isOngoingTraining && !isTrainingButtonDisabled && "28px",
              }}
              onClick={() => clickTabHandler("training-tutorial")}
              className={
                modalActiveTab === "training-tutorial"
                  ? "faqmodal-header-title active"
                  : "faqmodal-header-title"
              }
            >
              WELCOME TO TRAINING
            </div>
            <div
              style={{
                display: isTrainingButtonDisabled && "none",
                fontSize:
                  isOngoingTraining && !isTrainingButtonDisabled && "28px",
              }}
              onClick={() => clickTabHandler("training")}
              className={
                modalActiveTab === "training"
                  ? "faqmodal-header-title active"
                  : "faqmodal-header-title"
              }
            >
              FAQ
            </div>
            <div
              style={{
                display: isBattleButtonDisabled && "none",
                fontSize: isOngoingBattle && !isBattleButtonDisabled && "28px",
              }}
              onClick={() => clickTabHandler("battle-tutorial")}
              className={
                modalActiveTab === "battle-tutorial"
                  ? "faqmodal-header-title active"
                  : "faqmodal-header-title"
              }
            >
              <div className="faq-button">WELCOME TO BATTLE</div>
            </div>
            <div
              style={{
                display: isBattleButtonDisabled && "none",
                fontSize: isOngoingBattle && !isBattleButtonDisabled && "28px",
              }}
              onClick={() => clickTabHandler("battle")}
              className={
                modalActiveTab === "battle"
                  ? "faqmodal-header-title active"
                  : "faqmodal-header-title"
              }
            >
              <div className="faq-button">FAQ</div>
            </div>
            <div
              style={{
                display: isRescueButtonDisabled && "none",
                fontSize: isOngoingRescue && !isRescueButtonDisabled && "28px",
              }}
              onClick={() => clickTabHandler("rescue-tutorial")}
              className={
                modalActiveTab === "rescue-tutorial"
                  ? "faqmodal-header-title active"
                  : "faqmodal-header-title"
              }
            >
              <div className="faq-button">WELCOME TO RESCUE</div>
            </div>
            <div
              style={{
                display: isRescueButtonDisabled && "none",
                fontSize: isOngoingRescue && !isRescueButtonDisabled && "28px",
              }}
              onClick={() => clickTabHandler("rescue")}
              className={
                modalActiveTab === "rescue"
                  ? "faqmodal-header-title active"
                  : "faqmodal-header-title"
              }
            >
              <div className="faq-button">FAQ</div>
            </div>
          </div>
        </div>
        {modalActiveTab === "training-tutorial" && (
          <div className="faqmodal-content">
            <p className="tutorial-text">
              NBs are basically locked to your wallet and cannot go on missions
              (non-transferable tokens). The only way for a NB to become
              unlocked is if a FB is staked with it for 48 hours.
            </p>

            <p className="tutorial-text">
              During this time, no staking rewards would be earned by the NB or
              FB since the staking is just to convert the NB into a FB.
            </p>

            <p className="tutorial-text">
              ⚠️ Note: After 48 hours, you would have to unstake the FB and NB
              manually using the barbearians website.
            </p>
          </div>
        )}

        {modalActiveTab === "battle-tutorial" && (
          <div className="faqmodal-content">
            <p className="tutorial-text">
              With BATTLES, you can stake your favourite FB to earn some GOLDs.
            </p>

            <p className="tutorial-text">
              The battle mission is available for every unlocked Fighter Bear
              Bear.
            </p>

            <p className="tutorial-text">
              You can view the eligible GOLD amount when you send your FB to
              battle.
            </p>

            <p className="tutorial-text">
              ⚠️ Note: A market price change in Ethereum such as above/below +-
              6% would lead the bull to capture your FB as hostage unless
              unstaked.
            </p>
          </div>
        )}

        {modalActiveTab === "rescue-tutorial" && (
          <div className="faqmodal-content">
            <p className="tutorial-text">
              If you have a locked Hostage Bear and a FB, the FB can be staked
              for 48 hours with a Hostage Bear to unlock it back into a FB.
              During this time for the 48 hour period, both bears will
              accumulate GOLD.
            </p>

            <p style={{ textIndent: 0 }} className="tutorial-text">
              • Hostage bears would remain on the -50% daily reward.
            </p>

            <p
              style={{ textIndent: 0, position: "relative", top: "-1rem" }}
              className="tutorial-text"
            >
              • Staked FBs will earn base price staking rewards regardless of
              ETH price for the 48 hours.
            </p>

            <p className="tutorial-text">
              You would have the flexibility to specify which Hostage Bear the
              FB is rescuing.
            </p>

            <p className="tutorial-text">
              ⚠️ Note: After 48 hours, both bears will stop accumulating staking
              rewards and you will need to be unstaked the bears manually to
              claim the tokens (and unlock the bears).
            </p>
          </div>
        )}

        {modalActiveTab === "training" && (
          <div className="faqmodal-content">
            <div className="faq-question">
              <div className="faqmodal-content-title">
                1. Why am I not able to sell or transfer my Noobie Bear tokens?
              </div>
              <p>
                Noobie Bears are locked to the users wallet and cannot be
                transferred/sold. You would need to train your NB with a FB (by
                staking them together for 48hrs) to unlock your NB and covert it
                into a FB.
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                2. Can I choose any Fighter Bear to train my Noobie Bear?
              </div>
              <p>Any unlocked FB can be chosen to train your NBs.</p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                3. Will I have to incur gas to unstake the bears after 48hr
                period expires?
              </div>
              <p>
                Yes, you would need to initiate a transaction on the blockchain
                to unstake your bears after the 48hr period expires. However,
                once tx is successful, both of your bears would get unlocked and
                NB would get converted to FB.
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                4. Would my bears earn any GOLDs during training missions?
              </div>
              <p>
                No staking rewards would be earned by the NB or FB while in
                training mission. However, once training has been completed,
                your NB would also get converted to a FB (which can go on battle
                missions to earn GOLDs).
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                5. Is there any way for my Noobie Bear to earn GOLDs?
              </div>
              <p>
                Noobie Bears cannot earn GOLDs. You would need to train your NB
                and covert them into FB in order for them to be able to earn
                GOLDs.
              </p>
            </div>
          </div>
        )}
        {modalActiveTab === "battle" && (
          <div className="faqmodal-content">
            <div className="faq-question">
              <div className="faqmodal-content-title">1. What are GOLDs?</div>
              <p>
                GOLD is a digital token (not a crypto token) that can be earned
                though going on missions (Battle, and Rescue). These tokens can
                be spent on the Barbearians marketplace on the website to
                purchase more items/unlock rewards.
                <span className="note">*Note: GOLDs are not transferable.</span>
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                2. Would I be receiving a Fighter Bear (FB) or a Noobie Bear
                (NB)?
              </div>
              <p>
                Of the first half of purchasable NFTs auctioned, the top half of
                bids will be minted FBs and the second half will be NBs.
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                3. How many GOLDs would I yield while on a battle mission?
              </div>
              <p style={{ textIndent: "0" }}>
                FBs can be sent into battle to accumulate GOLD.
              </p>{" "}
              <p style={{ textIndent: "0" }}>
                The daily base staking reward = 100 GOLDs.
              </p>
              <p style={{ textIndent: "0" }}>
                There will be a multiplier for staking rewards based on the
                price fluctuation of ETH from the price at stake vs current
                price.
              </p>
              <p style={{ textIndent: "0" }}>
                • For each 1% increase in ETH price there will be a -10%
                decrease in daily staking reward up to -50%
              </p>
              <p style={{ textIndent: "0" }}>
                • For each 1% decrease in ETH price there will be a 10% increase
                in daily staking rewards up to 50%.
              </p>
              <p>
                ○ base price + (Base price * 0.1 * -X) → where X is the percent
                difference in
              </p>
              <p style={{ textIndent: "30px" }}>
                ■ ETH price - 2 Percent decrease in ETH Price
              </p>
              <p style={{ textIndent: "30px" }}>
                ■ 100 + (100 * 0.1 * -(-2)) = 100 + (100 * .2) = 100 + 20 = 120
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                4. What happens if the price of ETH rises by 6% from the price
                of ETH at stake?
              </div>
              <p>
                If the price of ETH rises by 6% from the price of ETH at stake,
                then their bear will be “taken hostage” which means it will be
                temporarily locked to their wallet.
              </p>
              <p>
                GOLDs will continue to be rewarded daily but at a 50% decrease
                which is as calculated at the time of becoming hostage and will
                continue at that rate regardless of price fluctuations.
              </p>
              <p>
                Please note, at this stage the FB would be taken into hostage
                and get converted to a ‘Hostage Bear’.
              </p>
              <p>
                If the Hostage Bear is not rescued within 4 weeks, the NFT will
                permanently locked to the users wallet. We call these bear
                Martyr Bears.
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                5. When can I unstake my Fighter Bear?
              </div>
              <p>
                FBs can be staked and unstaked at anytime (this excludes Hostage
                Bears and Martyr Bears).
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                6. When would I receive the earned GOLDs?
              </div>
              <p>
                The GOLDs would get credited to your BarBearians account (which
                can be accessed by logging into the website) as soon as you
                ‘unstake’ your Fighter Bear token.
              </p>
            </div>
          </div>
        )}
        {modalActiveTab === "rescue" && (
          <div className="faqmodal-content">
            <div className="faq-question">
              <div className="faqmodal-content-title">
                1. Why am I not able to sell or transfer my Hostage Bear tokens?
              </div>
              <p>
                Hostage Bears are locked to the users wallet and cannot be
                transferred/sold. You would need to rescue your Hostage Bear
                with a FB (by staking them together for 48hrs) to unlock your
                Hostage Bear and covert it into a FB.
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                2. How much time do I have to rescue my Hostage Bear?
              </div>
              <p>
                You would need to rescue your hostage bear within 4 weeks (from
                the time it was taken into hostage), after which if not rescued,
                the Hostage Bear would get converted to a Martyr Bear which
                would get permanently locked to your wallet.
                <span className="note">
                  *Note: Martyr Bears are not transferable and would not yield
                  any GOLDs.
                </span>
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                3. Can I choose any Fighter Bear to rescue my Hostage Bear?
              </div>
              <p>
                Any unlocked FB can be chosen to rescue your Hostage Bear. The
                rescue mission would take 48hrs to get completed.
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                4. Will I have to incur gas to unstake the bears after 48hr
                period expires?
              </div>
              <p>
                Yes, you would need to initiate a transaction on the blockchain
                to unstake your bears after the 48hr period expires. However,
                once TX is successful, both of your bears would get unlocked and
                Hostage Bear would get converted to FB.
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                5. Would my bears earn any GOLDs during rescue missions?
              </div>
              <p>
                During this time for the 48 hour period, both bears will
                accumulate GOLD.
              </p>
              <p style={{ textIndent: "0" }}>
                • Hostage bears would remain on the -50% daily reward.
              </p>
              <p style={{ textIndent: "0" }}>
                • Staked FBs will earn base price staking rewards regardless of
                ETH price for the 48 hours.
              </p>
            </div>
            <div className="faq-question">
              <div className="faqmodal-content-title">
                6. What happens to any unclaimed GOLD tokens if I’m unable to
                rescue the bear in time?
              </div>
              <p>
                GOLDs will NOT continue to be rewarded daily if hostage bear
                gets converted to a Martyr Bear and any unclaimed rewards will
                be forever locked.
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InfoPopup;
