import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../components/context/context";
import { getMarketData } from "./MarketplaceService";
import { nanoid } from "nanoid";
import { notifySuccess, truncateString } from "../../utils/functions";
import Skeleton from "react-loading-skeleton";
import { gsap } from "gsap";

import "./Marketplace.scss";
import "react-loading-skeleton/dist/skeleton.css";
import Cart from "./Cart";
import Howitworks from "./Howitworks";
import Footer from "../../components/Footer/Footer";

// Assets
import { ReactComponent as RepeatIcon } from "../../assets/media/icons/repeatIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/media/icons/SearchIcon.svg";
import { ReactComponent as BackgroundIcon } from "../../assets/media/icons/BackgroundFilter.svg";
import { ReactComponent as SearchPlus } from "../../assets/media/icons/PlusSearch.svg";
import { ReactComponent as SearchMinus } from "../../assets/media/icons/MinusSearch.svg";
import { ReactComponent as CartIcon } from "../../assets/media/icons/cart.svg";
import { ReactComponent as CartFullIcon } from "../../assets/media/icons/cartFull.svg";
import { ReactComponent as InfoIcon } from "../../assets/media/icons/InfoIcon.svg";
import { ReactComponent as Arrow } from "../../assets/svg/arrow.svg";
import WeaponPackIcon from "../../assets/media/icons/WeaponPackFilter.png";
import FurIcon from "../../assets/media/icons/FurFilterIcon.png";
import ArmorIcon from "../../assets/media/icons/ArmorFilterIcon.png";
import ExpressionIcon from "../../assets/media/icons/ExpressionFilterIcon.png";
import FacesTwoIcon from "../../assets/media/icons/FacesTwoFilterIcon.png";
import AccessoryIcon from "../../assets/media/icons/AccessoryFilterIcon.png";
import HeadpieceIcon from "../../assets/media/icons/HeadpieceFilterIcon.png";
import WeaponFrontIcon from "../../assets/media/icons/WeaponFrontFilterIcon.png";
import SpecialEffectIcon from "../../assets/media/icons/SpecialEffectFilterIcon.png";
import BearCoin from "../../assets/media/icons/BearCoinSmall.png";
import checkbox from "../../assets/media/icons/checkbox.png";
import checkboxChecked from "../../assets/media/icons/checkboxChecked.png";

import HallButton from "../../components/GotoHallButton/HallButton";

// TODO: I will change the Background icon
// TODO: Do selected filters from 'fur' to 'special effect' filters
// TODO: I will fix the Go to Hall button's position

const GOLD_PRICE = 500; // Passing to Cart component - Dummy gold

const Marketplace = () => {
  const { setShowNavBar } = useContext(MainContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeMetadata, setActiveMetadata] = useState([]);
  const [showCart, setShowCart] = useState(false); // Cart visibility
  const [marketCart, setMarketCart] = useState([]); // Cart array
  const [isLoading, setIsLoading] = useState(false);
  const [nftData, setNftData] = useState([]);
  const [traitFilter, setTraitFilter] = useState([]);

  // Filtering backgrounds
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [headerArrayData, setHeaderArrayData] = useState([]);
  const [filterState, setFilterState] = useState({});

  let attributesGet = "";

  useEffect(() => {
    document.title = "Bear&Bull Game • Marketplace";

    setShowNavBar(true);
    getDataForMarketplace();

    const handleEscKey = (event) => {
      if (event.keyCode === 27) {
        setShowCart(false);
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  const getDataForMarketplace = async () => {
    setIsLoading(true);
    try {
      const api_response = await getMarketData();

      if (api_response.data.status) {
        setNftData(api_response.data.OnlyTokenArray);

        const traitFilterKeys = Object.keys(api_response.data.FilterArray);
        setTraitFilter(traitFilterKeys);

        const headerArrayData = {};

        traitFilterKeys.forEach((header) => {
          headerArrayData[header] = api_response.data.FilterArray[header];
        });

        const dataArray = Object.entries(headerArrayData).map(
          ([header, data]) => ({
            header,
            data,
          })
        );

        setHeaderArrayData(dataArray);

        const falseBooleansObject = traitFilterKeys.reduce((acc, trait) => {
          acc[trait] = false;
          return acc;
        }, {});

        setFilterState(falseBooleansObject);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoriesFilter = (filterName) => {
    setFilterState((prevState) => ({
      ...prevState,
      [filterName]: !prevState[filterName],
    }));
  };

  const addToCart = (item) => {
    setMarketCart((prevCart) => [...prevCart, item]);

    if (marketCart.length === 0) {
      notifySuccess("Item added to cart successfully!");
    }
  };

  const removeFromCart = (itemId) => {
    setMarketCart((prevCart) =>
      prevCart.filter((item) => item.name !== itemId)
    );
  };

  const handleMetadataBtnClick = (index) => {
    if (activeMetadata?.includes(index)) {
      setActiveMetadata((prevData) => prevData.filter((i) => i !== index));
    } else {
      setActiveMetadata((prevData) => [...prevData, index]);
    }
  };

  // Animation
  const handlePopupOpen = () => {
    setIsPopupOpen(true);

    setTimeout(() => {
      gsap.to([".howitworks-popup-back", ".howitworks-popup"], {
        opacity: 1,
        duration: 0.5,
        stagger: 0.1,
      });
    }, 1);
  };

  // Filtering functions
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedFilters((prevSelectedFilters) => {
      if (prevSelectedFilters.includes(value)) {
        return prevSelectedFilters.filter((filter) => filter !== value);
      } else {
        return [...prevSelectedFilters, value];
      }
    });
  };

  const clearFilterHandler = () => {
    if (selectedFilters.length > 0) {
      setSelectedFilters([]);
      setFilterState((prevState) => {
        const newState = {};
        Object.keys(prevState).forEach((filterName) => {
          newState[filterName] = false;
        });
        return newState;
      });

      notifySuccess("All filters have been cleared successfully!");
    }
  };

  return (
    <>
      {/* <HallButton /> */}
      {isPopupOpen && <Howitworks setIsPopupOpen={setIsPopupOpen} />}
      <Cart
        removeFromCart={removeFromCart}
        marketCart={marketCart}
        setMarketCart={setMarketCart}
        showCart={showCart}
        setShowCart={setShowCart}
        GOLD_PRICE={GOLD_PRICE}
      />

      <div onClick={() => setShowCart(true)} className="cart-btn">
        {marketCart.length === 0 ? (
          <CartIcon className="cart-icon" />
        ) : (
          <CartFullIcon className="cart-icon" />
        )}
        {marketCart.length !== 0 && (
          <div className="cart-count">{marketCart.length}</div>
        )}
      </div>

      <div className="marketplace">
        <div className="marketplace-title">
          <h3>MARKETPLACE</h3>
          <div onClick={handlePopupOpen} className="howitworks-marketplace-btn">
            <InfoIcon />
            <div className="howitworks-marketplace-text">How it works?</div>
          </div>
        </div>
        <div className="marketplace-inner">
          <div className="marketplace-left">
            <div className="filters-box">
              <div className="filters-box-top">
                <div className="filters">FILTERS</div>
                <div onClick={clearFilterHandler} className="clear-button">
                  <RepeatIcon className="repeat-icon" />
                  <div className="clear">Clear</div>
                </div>
              </div>

              {/* Search Container */}
              <div className="bears-search">
                <SearchIcon />
                <input
                  type="text"
                  className="bears-search-input"
                  placeholder="Find by ID..."
                />
              </div>

              {isLoading ? (
                <Skeleton
                  borderRadius={10}
                  baseColor="#686669"
                  highlightColor="#807e81"
                  count={traitFilter?.length || 7}
                  height="4rem"
                />
              ) : (
                traitFilter?.map((trait, index) => (
                  <div
                    key={index}
                    onClick={() => handleCategoriesFilter(trait)}
                    className={
                      filterState[trait]
                        ? "background-filter selected"
                        : "background-filter"
                    }
                  >
                    <div className="background-filter-top">
                      <div className="background-filter-left">
                        <div className="background">{trait}</div>
                      </div>
                      <div className="background-filter-right">
                        <Arrow
                          className={
                            filterState[trait]
                              ? "arrow-icon open"
                              : "arrow-icon"
                          }
                        />
                      </div>
                    </div>

                    <div className="select-filters-wrapper">
                      {headerArrayData
                        .find((headerData) => headerData.header === trait)
                        ?.data.map((filter, filterIndex) => (
                          <div
                            key={filterIndex}
                            className="background-filter-item"
                          >
                            <label>
                              <img
                                draggable="false"
                                className="checkbox-img"
                                alt=""
                                src={
                                  selectedFilters.includes(filter)
                                    ? checkboxChecked
                                    : checkbox
                                }
                              />
                              <input
                                type="checkbox"
                                value={filter}
                                checked={selectedFilters.includes(filter)}
                                onChange={handleCheckboxChange}
                                hidden
                              />
                              {filter}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Marketplace right */}
          <div className="marketplace-right">
            <div className="marketplace-right-top-wrapper">
              <div className="marketplace-right-top">
                Bears Count: {isLoading ? "Loading..." : nftData.length}
              </div>
              <div className="marketplace-right-wrapper">
                <div className="daily-rewards-wrapper">
                  <div className="daily-rewards-left">
                    <img src={BearCoin} alt="" />
                  </div>
                  <div className="daily-rewards-right">
                    <div className="daily-rewards-info">{GOLD_PRICE} GOLD</div>
                    <div className="daily-rewards-title">BALANCE</div>
                  </div>
                </div>
              </div>
            </div>

            {isLoading ? (
              <Skeleton
                borderRadius={10}
                baseColor="#686669"
                highlightColor="#807e81"
                width={"15rem"}
                height={"22rem"}
                count={12}
                containerClassName="marketplace-skeleton-wrapper"
              />
            ) : (
              <div className="bear-market">
                {nftData.map((bear, i) => (
                  <div
                    key={nanoid()}
                    className={
                      marketCart.some((item) => item.name === bear.name)
                        ? "marketplace-card-wrapper selected"
                        : "marketplace-card-wrapper"
                    }
                  >
                    <div className="marketplace-card-image-wrapper">
                      <img
                        draggable="false                           "
                        src={bear.image}
                        alt=""
                      />
                      <div
                        onClick={() => handleMetadataBtnClick(i)}
                        className={
                          activeMetadata?.includes(i)
                            ? "metadata-info-btn active"
                            : "metadata-info-btn"
                        }
                      >
                        <span>
                          {activeMetadata?.includes(i)
                            ? "Rank Info"
                            : "Metadata Info"}
                        </span>
                        <InfoIcon className="info-icon" />
                      </div>
                    </div>
                    <div className="marketplace-card-bottom">
                      <div className="bear-information-wrapper">
                        {activeMetadata?.includes(i) ? (
                          <>
                            <div className="bear-information-top">
                              <div className="bear-type">
                                <div className="type">Background</div>
                                <div className="bear-type-info">
                                  {bear.attributes[0].value}
                                </div>
                              </div>
                              <div className="bear-id">
                                <div className="id">Fur</div>
                                <div
                                  style={{
                                    fontSize:
                                      bear.attributes[1].value.length >= 7
                                        ? 16
                                        : 24,
                                  }}
                                  className="bear-id-number fur-text"
                                >
                                  {bear?.attributes[1].value.length >= 12
                                    ? truncateString(
                                        bear.attributes[1].value,
                                        9
                                      )
                                    : bear.attributes[1].value}
                                  {bear?.attributes[1].value.length >= 12 && (
                                    <div className="fur-tooltip-text">
                                      {bear.attributes[1].value}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="bear-information-top">
                              <div className="bear-type">
                                <div className="type">Front</div>
                                <div className="bear-type-info">
                                  {bear.attributes[0].value}
                                </div>
                              </div>
                              <div className="bear-id">
                                <div className="id back-name">Back</div>
                                <div className="bear-id-number back-coat">
                                  {truncateString(
                                    bear?.attributes[3].value,
                                    10
                                  )}
                                  <div className="tooltip-text">
                                    {bear.attributes[3].value}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="bear-information-top not-metadata">
                            <div className="bear-type not-metadata">
                              <div className="type bear-id">ID</div>
                              <div className="bear-type-info not-metadata">
                                #{bear.name}
                              </div>
                            </div>
                            <div className="bear-id not-metadata">
                              <div className="id bear-rank not-metadata">
                                RANK
                              </div>
                              <div className="bear-id-number not-metadata">
                                RARE
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {marketCart.some((item) => item.name === bear.name) ? (
                        <div
                          onClick={() => removeFromCart(bear.name)}
                          className="buy-button"
                        >
                          <div className="price-wrapper">
                            <span className="price remove-text">REMOVE</span>
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() => addToCart(bear)}
                          className="buy-button"
                        >
                          <div className="price-wrapper">
                            <img draggable="false" src={BearCoin} alt="" />
                            <span className="price">ADD TO CART</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Card end */}
            <div className="loadmore-wrapper">
              <div className="load-more-btn">Load More</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Marketplace;
