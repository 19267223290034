import React, { useEffect, useContext } from "react";
import useSound from "use-sound";
import { useNavigate } from "react-router-dom";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";

import { motion } from "framer-motion";

import { MainContext } from "../../components/context/context";
import "./Intro.scss";

// Assets
import BlackLogo from "../../assets/media/images/BlackLogo.png";
import checkbox from "../../assets/media/icons/checkbox.png";
import checkboxChecked from "../../assets/media/icons/checkboxChecked.png";
import checkboxNotHover from "../../assets/media/icons/checkboxNotHover.png";
import cloudTopright from "../../assets/media/Intro/Clouds/cloud-topright.png";
import cloudTopleft from "../../assets/media/Intro/Clouds/cloud-topleft.png";
import cloudTopleftTwo from "../../assets/media/Intro/Clouds/cloud-topleft2.png";
import cloudBottomleft from "../../assets/media/Intro/Clouds/cloud-bottomleft.png";
import cloudBottommiddle from "../../assets/media/Intro/Clouds/cloud-bottommiddle.png";
import cloudBottomright from "../../assets/media/Intro/Clouds/cloud-bottomright.png";
import cloudMiddle from "../../assets/media/Intro/Clouds/cloudMiddle.png";

// Sounds
import StartEffect from "../../assets/sound/withSound.mp3";
import ClickSound from "../../assets/sound/onClickSound.wav";
import IntroWhoosh from "../../assets/sound/introWhoosh.mp3";

const Intro = () => {
  const navigate = useNavigate();
  const { setPlayMainSong, setIsSoundEnabled, isSoundEnabled, setShowNavBar } =
    useContext(MainContext);

  // Sounds
  const [play] = useSound(StartEffect, { volume: 0.7 });
  const [playWhoosh] = useSound(IntroWhoosh);
  const [playClick] = useSound(ClickSound, { volume: 0.55 });

  useEffect(() => {
    setShowNavBar(false);

    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";
  }, []);

  const continueHandler = (soundBoolean) => {
    if (isSoundEnabled) {
      play();
      setTimeout(() => {
        playWhoosh();
      }, 750);
    }

    setTimeout(
      () => {
        setPlayMainSong(soundBoolean);
      },
      isSoundEnabled ? 2100 : 0
    );
  };

  return (
    <div className="intro">
      <motion.div
        exit={{ opacity: 0 }}
        transition={{ ease: "easeInOut", delay: 0.5, duration: 1 }}
        className="intro-clouds-background"
      />
      <MouseParallaxContainer className="parallax-container">
        <MouseParallaxChild
          className="cloud-topleft"
          factorX={0.03}
          factorY={0.05}
        >
          <motion.img
            initial={{ opacity: 0, y: "-50%", x: "-40%" }}
            animate={{ opacity: 1, y: "-5%", x: "-2%" }}
            exit={{ opacity: 0, y: "-50%", x: "-40%" }}
            transition={{ duration: 1.9, ease: "easeInOut" }}
            src={cloudTopleft}
            alt=""
          />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="cloud-topleft-two"
          factorX={0.005}
          factorY={0.005}
        >
          <motion.img
            initial={{ opacity: 0.5, y: "-50%" }}
            animate={{ opacity: 1, y: 0, x: "-2%" }}
            exit={{ opacity: 0, y: "-50%" }}
            transition={{ duration: 2, ease: "easeInOut" }}
            src={cloudTopleftTwo}
            alt=""
          />
        </MouseParallaxChild>

        <MouseParallaxChild
          className="cloud-bottomleft"
          factorX={0.0275}
          factorY={0.0157}
        >
          <motion.img
            initial={{ opacity: 0.5, y: "50%", x: "-40%" }}
            animate={{ opacity: 1, y: "-1%", x: "-3%" }}
            exit={{ opacity: 0, y: "50%", x: "-40%" }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            src={cloudBottomleft}
            alt=""
          />
        </MouseParallaxChild>

        <MouseParallaxChild
          className="cloud-bottommiddle"
          factorX={0.015}
          factorY={0.02}
        >
          <motion.img
            initial={{ opacity: 0.5, y: "60%" }}
            animate={{ opacity: 1, y: "2%", x: "-2%" }}
            exit={{ opacity: 0, y: "60%" }}
            transition={{ duration: 1.75, ease: "easeInOut" }}
            src={cloudBottommiddle}
            alt=""
          />
        </MouseParallaxChild>

        <MouseParallaxChild
          className="cloud-bottommiddle-two"
          factorX={0.0075}
          factorY={0.015}
        >
          <motion.img
            initial={{ opacity: 0.5, y: "60%" }}
            animate={{ opacity: 1, y: "-11.5%", x: "17.5%" }}
            exit={{ opacity: 0, y: "60%" }}
            transition={{ duration: 1.75, ease: "easeInOut" }}
            src={cloudMiddle}
            alt=""
          />
        </MouseParallaxChild>

        <MouseParallaxChild
          className="cloud-bottomright"
          factorX={0.005}
          factorY={0.02}
        >
          <motion.img
            initial={{ opacity: 0.5, y: "60%", x: "40%" }}
            animate={{ opacity: 1, y: "1%", x: "2%" }}
            exit={{ opacity: 0, y: "60%", x: "40%" }}
            transition={{ duration: 1.75, ease: "easeInOut" }}
            src={cloudBottomright}
            alt=""
          />
        </MouseParallaxChild>

        <MouseParallaxChild factorX={0.015} factorY={0.005}>
          <motion.img
            initial={{ opacity: 0.5, y: "-60%", x: "40%" }}
            animate={{ opacity: 1, y: "-2%", x: "2%" }}
            exit={{ opacity: 0, y: "-60%", x: "40%" }}
            transition={{ duration: 1.75, ease: "easeInOut" }}
            src={cloudTopright}
            alt=""
            className="cloud-topright"
          />
        </MouseParallaxChild>
      </MouseParallaxContainer>

      <div className="logo-wrapper">
        <motion.img
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -250 }}
          transition={{ delay: 0.5, duration: 1.5, ease: "easeInOut" }}
          className="intro-black-logo"
          src={BlackLogo}
          alt="BarBearians Logo"
        />
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6, delay: 1 }}
          className="intro-cta"
        >
          THE GRAND HALL OF BARBEARIANS AWAITS YOU!
        </motion.p>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6, delay: 0.5 }}
        className="intro-buttons-wrapper"
      >
        <div
          onClick={() => {
            continueHandler(isSoundEnabled);
            navigate("/hall");
          }}
          className="enter-button"
        >
          ENTER
        </div>
        <div
          onClick={() => {
            setIsSoundEnabled(!isSoundEnabled);
            if (!isSoundEnabled) {
              playClick();
            }
          }}
          className="sound-checkbox"
        >
          {!isSoundEnabled && (
            <img src={checkboxNotHover} className="checkbox-not-hover" alt="" />
          )}
          <img src={isSoundEnabled ? checkboxChecked : checkbox} alt="" />
          Sound
        </div>
      </motion.div>
    </div>
  );
};

export default Intro;
