import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../../components/context/context";

import { motion } from "framer-motion";

import "./HallScene.scss";

// Assets
import Torchlights from "../../assets/media/Intro/Hall/torchlights.gif";
import LogoMain from "../../assets/media/images/bearbull.png";
import HallFrame from "../../assets/media/images/hallFrame.png";
import HallFrameLocked from "../../assets/media/images/hallFrameLocked.png";
import TwitterPixel from "../../assets/media/icons/TwitterPixel.png";
import DiscordPixel from "../../assets/media/icons/DiscordPixel.png";

import AnubisDark from "../../assets/media/Intro/Hall/anubisBearDark.webm";
import AnubisHover from "../../assets/media/Intro/Hall/anubisBearHover.webm";
import KingDark from "../../assets/media/Intro/Hall/bearKingDark.webm";
import KingHover from "../../assets/media/Intro/Hall/bearKingHover.webm";
import LichDark from "../../assets/media/Intro/Hall/lichBearDark.webm";
import LichHover from "../../assets/media/Intro/Hall/lichBearHover.webm";
import DemonDark from "../../assets/media/Intro/Hall/demonBearDark.webm";
import DemonHover from "../../assets/media/Intro/Hall/demonBearHover.webm";
import CowboyDark from "../../assets/media/Intro/Hall/cowboyBearDark.webm";
import CowboyHover from "../../assets/media/Intro/Hall/cowboyBearHover.webm";
import SageDark from "../../assets/media/Intro/Hall/sageBearDark.webm";
import SageHover from "../../assets/media/Intro/Hall/sageBearHover.webm";
import MoneybagDark from "../../assets/media/Intro/Hall/moneybagBearDark.webm";
import MoneybagHover from "../../assets/media/Intro/Hall/moneybagBearHover.webm";
import BullDark from "../../assets/media/Intro/Hall/bullBearDark.webm";
import BullHover from "../../assets/media/Intro/Hall/bullBearHover.webm";

const HallScene = () => {
  const navigate = useNavigate();
  const { setShowNavBar } = useContext(MainContext);

  // Navigating states
  const [navigatingHome, setNavigatingHome] = useState(false);
  const [navigatingWLChecker, setNavigatingWLChecker] = useState(false);
  const [navigatingWhitepaper, setNavigatingWhitepaper] = useState(false);
  const [navigatingMarketplace, setNavigatingMarketplace] = useState(false);
  const [navigatingTOS, setNavigatingTOS] = useState(false);

  useEffect(() => {
    document.title = "Bear&Bull Game • Hall";

    setShowNavBar(false);
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";
  }, [setShowNavBar]);

  // Blocking the context menu
  const blockContextMenu = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("contextmenu", blockContextMenu);

    return () => {
      document.removeEventListener("contextmenu", blockContextMenu);
    };
  }, []);

  const handleNavigate = (path) => {
    // check the path and give the correct animation with switch case
    switch (path) {
      case "/home":
        setNavigatingHome(true);
        setNavigatingWLChecker(false);
        setNavigatingWhitepaper(false);
        setNavigatingMarketplace(false);
        setNavigatingTOS(false);
        break;

      case "/theallowlist":
        setNavigatingHome(false);
        setNavigatingWLChecker(true);
        setNavigatingWhitepaper(false);
        setNavigatingMarketplace(false);
        setNavigatingTOS(false);
        break;

      case "/whitepaper":
        setNavigatingHome(false);
        setNavigatingWLChecker(false);
        setNavigatingWhitepaper(true);
        setNavigatingMarketplace(false);
        setNavigatingTOS(false);
        break;

      case "/marketplace":
        setNavigatingHome(false);
        setNavigatingWLChecker(false);
        setNavigatingWhitepaper(false);
        setNavigatingMarketplace(true);
        setNavigatingTOS(false);
        break;

      case "/tos":
        setNavigatingHome(false);
        setNavigatingWLChecker(false);
        setNavigatingWhitepaper(false);
        setNavigatingMarketplace(false);
        setNavigatingTOS(true);
        break;

      default:
        break;
    }

    navigate(path);
  };

  // Determine the path to go and shape the exit animation. It will leave the clicked bear normal and make the other bears transparent

  const exitAnimation = (path) => {
    let opacity = 0;

    switch (path) {
      case "/home":
        opacity = navigatingHome ? 1 : 0;
        break;
      case "/theallowlist":
        opacity = navigatingWLChecker ? 1 : 0;
        break;
      case "/whitepaper":
        opacity = navigatingWhitepaper ? 1 : 0;
        break;
      case "/marketplace":
        opacity = navigatingMarketplace ? 1 : 0;
        break;
      case "/tos":
        opacity = navigatingTOS ? 1 : 0;
        break;
      default:
        opacity = 0;
        break;
    }

    return { opacity };
  };

  return (
    <div className="hall-scene">
      <div className="social-links-wrapper">
        <div
          onClick={() =>
            window.open("https://twitter.com/BearBullGame", "_blank")
          }
          className="twitter-button"
        >
          <img src={TwitterPixel} alt="" />
        </div>
        {/* <div className="discord-button">
          <img src={DiscordPixel} alt="" />
        </div> */}
        {/* We'll use this when we got Discord up and running */}
      </div>
      <div className="hall-background" />
      <div className="hall-logo-wrapper">
        <motion.img
          initial={{ opacity: 0, y: 150 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ ease: "easeOut", duration: 0.75 }}
          onClick={() => navigate("/home")}
          className="hall-logo"
          src={LogoMain}
          alt=""
        />
      </div>

      {/* Mobile hall links */}
      <div className="mobile-hall-links">
        <Link to="/home">HOME</Link>
        {/* <Link to="/holders-dash">HOLDERS DASH</Link> */}
        {/* <Link to="/mint">MINT</Link> */}
        <div
          onClick={() =>
            window.open("https://barbearians-pl.pages.dev/", "_blank")
          }
          className="barbearians-pl-link"
        >
          P&L TRACKER
        </div>
        <div
          onClick={() => window.open("https://sim.barbearians.xyz/", "_blank")}
          className="eth-simulator-link"
        >
          ETH SIMULATOR
        </div>
        {/* <Link to="/whitepaper">WHITEPAPER</Link> */}
        {/* <Link to="/theallowlist">ALLOWLIST</Link> */}
      </div>
      {/* Mobile hall links end */}

      <div className="torchlights-wrapper">
        <motion.img
          initial={{ filter: "brightness(0)" }}
          animate={{ filter: "brightness(50%)" }}
          exit={exitAnimation}
          transition={{ ease: "easeOut", duration: 1.5 }}
          src={Torchlights}
          className="torchlights-one"
          alt=""
        />
        <motion.img
          initial={{ filter: "brightness(0)" }}
          animate={{ filter: "brightness(50%)" }}
          exit={exitAnimation}
          src={Torchlights}
          className="torchlights-two"
          alt=""
        />
      </div>
      <div className="bears-container">
        <motion.div
          exit={exitAnimation("/home")}
          transition={{ ease: "easeOut", duration: 1.5 }}
          className="king-bear-wrapper"
        >
          <div className="mint-auction">
            <img src={HallFrame} className="hall-frame" alt="" />
            <div className="mint-auction-text">HOMEPAGE</div>
          </div>

          <div
            onClick={() => handleNavigate("/home")}
            className="king-bear-video hall-bear"
          >
            <video playsInline loop muted autoPlay src={KingDark} />
            <video
              className="king-hover"
              playsInline
              loop
              muted
              autoPlay
              src={KingHover}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ y: -250, x: -550, rotate: 20 }}
          animate={{ y: 0, x: -250, rotate: 0 }}
          exit={exitAnimation}
          transition={{ ease: "easeOut", duration: 1.5 }}
          className="demon-bear-wrapper"
        >
          <div className="holders-dash">
            <img src={HallFrame} className="hall-frame" alt="" />
            <div className="holders-dash-text">HOLDERS DASH</div>
          </div>
          <div className="demon-bear-video hall-bear">
            <video playsInline loop muted autoPlay src={DemonDark} />
            <video
              className="demon-hover"
              playsInline
              loop
              muted
              autoPlay
              src={DemonHover}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={exitAnimation}
          transition={{ ease: "easeOut", duration: 1.5 }}
          className="lich-bear-wrapper"
        >
          <div className="lich-glow" />
          <div className="loss-calculator">
            <img src={HallFrame} className="hall-frame" alt="" />
            <div className="loss-calculator-text">WL QUESTS</div>
          </div>
          <div
            onClick={() => navigate("/whitelist-quests")}
            className="lich-bear-video hall-bear"
          >
            <video playsInline loop muted autoPlay src={LichDark} />
            <video
              className="lich-hover"
              playsInline
              loop
              muted
              autoPlay
              src={LichHover}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={exitAnimation("/theallowlist")}
          transition={{ ease: "easeOut", duration: 1.5 }}
          className="anubis-bear-wrapper"
        >
          <div className="anubis-glow" />
          <div className="wl-checker">
            <img src={HallFrameLocked} className="hall-frame" alt="" />
            <div className="wl-checker-text">WL CHECKER</div>
          </div>
          <div
            // onClick={() => handleNavigate("/theallowlist")}
            className="anubis-bear-video hall-bear locked"
          >
            <video playsInline loop muted autoPlay src={AnubisDark} />
            <video
              className="anubis-hover"
              playsInline
              loop
              muted
              autoPlay
              src={AnubisHover}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={exitAnimation("/tos")}
          transition={{ ease: "easeOut", duration: 1.5 }}
          className="sage-bear-wrapper"
        >
          <div className="sage-glow" />
          <div className="tos">
            <img src={HallFrame} className="hall-frame" alt="" />
            <div className="tos-text">TERMS OF SERVICE</div>
          </div>
          <div className="sage-bear-video hall-bear">
            <video playsInline loop muted autoPlay src={SageDark} />
            <video
              className="sage-hover"
              playsInline
              loop
              muted
              autoPlay
              src={SageHover}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={exitAnimation}
          transition={{ ease: "easeOut", duration: 1.5 }}
          className="cowboy-bear-wrapper"
        >
          <div className="cowboy-glow" />
          <div className="eth-trader">
            <img src={HallFrameLocked} className="hall-frame" alt="" />
            <div className="eth-trader-text">MINT</div>
          </div>
          <div
            // onClick={() => window.open("https://sim.barbearians.xyz", "_blank")}
            className="cowboy-bear-video hall-bear locked"
          >
            <video playsInline loop muted autoPlay src={CowboyDark} />
            <video
              className="cowboy-hover"
              playsInline
              loop
              muted
              autoPlay
              src={CowboyHover}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={exitAnimation("/marketplace")}
          transition={{ ease: "easeOut", duration: 1.5 }}
          className="moneybag-bear-wrapper"
        >
          <div className="marketplace-glow" />
          <div className="hall-marketplace-text">
            <img src={HallFrameLocked} className="hall-frame" alt="" />
            <div className="hall-marketplace-text">MARKETPLACE</div>
          </div>
          <div className="moneybag-bear-video hall-bear locked">
            <video playsInline loop autoPlay src={MoneybagDark} />
            <video
              className="moneybag-hover"
              playsInline
              loop
              muted
              autoPlay
              src={MoneybagHover}
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={exitAnimation("/whitepaper")}
          transition={{ ease: "easeOut", duration: 1.5 }}
          className="bull-bear-wrapper"
        >
          <div className="bull-glow" />
          <div className="hall-whitepaper-text">
            <img src={HallFrameLocked} className="hall-frame" alt="" />
            <div className="hall-whitepaper-text">WHITEPAPER</div>
          </div>
          <div
            // onClick={() => handleNavigate("/whitepaper")}
            className="bull-bear-video hall-bear locked"
          >
            <video playsInline loop muted autoPlay src={BullDark} />
            <video
              className="bull-hover"
              playsInline
              loop
              muted
              autoPlay
              src={BullHover}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default HallScene;
