import React, { useState, useEffect } from "react";
import axios from "axios";
import Web3 from "web3";
import WebModal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import setAuthToken from "../utils/setAuthToken";

import { notifySuccess } from "../../utils/functions";

const UserContextTemplate = {
  userInfo: {
    walletAddress: null,
    walletConnected: false,
    userName: "none",
    userAvatar: "",
    eth: 0,
    usd: 0,
    eth_holding: 0,
    usd_holding: 0,
    islogin: false,
  },
  walletConnect: () => {},
  logout: () => {},
  reset: () => {},
  updateUser: () => {},
  getMyInfo: () => {},
  setUserInfo: () => {},
};

const UserContext = React.createContext(UserContextTemplate);

const providerOptions = {
  coinbaseWallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "web3modal",
      infuraId: "INFURA_ID",
      rpc: "",
      chainId: 1,
      darkMode: true,
    },
  },

  binancechainwallet: {
    package: true,
  },
};

const web3Modal = new WebModal({
  network: "mainnet",
  cacheProvider: false,
  providerOptions,
});

const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({
    walletAddress: null,
    walletConnected: false,
    userName: "none",
    userAvatar: "",
    eth: 0,
    eth_holding: 0,
    usd_holding: 0,
    usd: 0,
    islogin: false,
  });



  const logout = async () => {
    web3Modal.clearCachedProvider();
    localStorage.removeItem("id");
    localStorage.removeItem("jwt_token");
    setUserInfo({
      walletAddress: null,
      walletConnected: false,
      userName: "none",
      userAvatar: "",
      islogin: false,
      walletConnected: false,
    });
    notifySuccess("Successfully logged out!");
  };

  const reset = async () => {
    setAuthToken(localStorage.getItem("jwt_token"));
    const userId = localStorage.getItem("id");
    let status = await axios
      .patch(`${process.env.REACT_APP_BASEURL}users/reset/`, { userId: userId })
      .then((result) => {
        if (result.data.status) {
          setUserInfo((currValue) => ({
            ...currValue,
            islogin: true,
            eth: result.data.data.eth_balance,
            usd: result.data.data.usd_balance,
            eth_holding: result.data.data.eth_holding,
            usd_holding: result.data.data.usd_holding,
            userName: result.data.data.userName,
            userAvatar: result.data.data.userAvatar,
          }));
        } else {
          alert(result.data.message);
        }

        return result.data.status;
      });

    return status;
  };

  const updateUser = async (name, file) => {
    setAuthToken(localStorage.getItem("jwt_token"));
    const userId = localStorage.getItem("id");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", userId);
    formData.append("userName", name);

    let status = await axios
      .patch(`${process.env.REACT_APP_BASEURL}/users/`, formData)
      .then((result) => {
        if (result.data.status)
          setUserInfo((currValue) => ({
            ...currValue,
            userName: result.data.data.userName,
            userAvatar: result.data.data.userAvatar,
          }));

        return result.data.status;
      });

    return status;
  };



  return (
    <UserContext.Provider
      value={{
        userInfo,
        logout,
        reset,
        updateUser,
        setUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
export default UserProvider;
