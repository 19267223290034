import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./RoadMap.scss";

// Assets
import { ReactComponent as RightArrow } from "../../../assets/media/icons/rightArrow.svg";
import RoadmapPicture from "../../../assets/media/images/roadmapPic.png";
import ProgressBar from "../../../assets/media/icons/roadmapProgress.png";

gsap.registerPlugin(ScrollTrigger);
const RoadMap = () => {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".roadmap",
        start: "top center",
      },
    });

    const progressBox = {
      backgroundColor: "#7f1d1d",
      clipPath: "inset(0 100% 0 0)",
      duration: 2,
      ease: "power4.out",
    };

    tl.from(".progress-box", progressBox).from(
      ".progress-box-side",
      progressBox,
      "-=1"
    );
  }, []);

  return (
    <div className="roadmap">
      <div className="roadmap-box">
        <div className="roadmap-box-leftside">
          <img src={RoadmapPicture} alt="" />
        </div>
        <div className="roadmap-box-rightside">
          <div className="roadmap-title">2023 ROADMAP</div>
          <div className="roadmaps-wrapper">
            <div className="roadmap-headers">
              <div className="headers-wrapper">
                <div className="website-road">QUESTS</div>
                <div className="website-progress-bar-wrapper">
                  <img src={ProgressBar} alt="" />
                  <div className="progress-box" />
                </div>
              </div>
              <RightArrow />
              <div className="headers-wrapper">
                <div className="sidequests-road">SALE</div>
                <div className="sidequests-progress-bar">
                  <img src={ProgressBar} alt="" />
                  <div className="progress-box-side" />
                </div>
              </div>
              <RightArrow />
              <div className="headers-wrapper">
                {" "}
                <div className="auctionsale-road">MISSIONS</div>
                <div className="auction-progress-bar">
                  <img src={ProgressBar} alt="" />
                </div>
              </div>
              <RightArrow />
              <div className="headers-wrapper">
                <div className="allowlist-road">MARKETPLACE</div>
                <div className="allowlist-progress-bar">
                  <img src={ProgressBar} alt="" />
                </div>
              </div>
            </div>
          </div>
          <Marquee
            className="roadmap-marquee"
            gradientColor={[28, 30, 36]}
            gradient={true}
          >
            <span>CHAPTER 2</span>
            <span>COMING SOON</span>
            <span>CHAPTER 2</span>
            <span>COMING SOON</span>
            <span>CHAPTER 2</span>
            <span>COMING SOON</span>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
