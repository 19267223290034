import React from "react";
import { useBearsData } from "../../../../components/context/bearsContext";
import gsap from "gsap";
import { Link } from "react-router-dom";

import "./BecomeHostage.scss";

// Assets
import { ReactComponent as CloseIcon } from "../../../../assets/media/icons/CancelIcon.svg";
import { useNavigate } from "react-router-dom";

const BecomeHostage = () => {
  const { bearsData, setBecomeHostagePopup, setIsPopupClosed } = useBearsData();
  const navigate = useNavigate();
  const closeHandler = () => {
    setIsPopupClosed(true);
    const tl = gsap.timeline({
      defaults: { duration: 0.5, ease: "power2.inOut" },
    });

    tl.to(".become-hostage-popup", {
      opacity: 0,
    })
      .to(".become-hostage-back", { opacity: 0, duration: 0.7 }, "-=.4")
      .then(() => setBecomeHostagePopup(false));
  };


  return (
    <>
      <div onClick={closeHandler} className="become-hostage-back" />
      <div className="become-hostage-popup">
        <div onClick={closeHandler} className="close-button">
          <CloseIcon />
        </div>
        <div className="becomehostage-top">
          <div className="ohno-text">
            <p>OH NO!</p>
          </div>
          <div className="become-hostage-text">
            <p>Your bear has been taken hostage.</p>
          </div>
        </div>

        <div className="becomehostage-bottom">
          <div className="hostage-image">
            <img src={bearsData[0]?.bearImage} alt="" />
            <div className="hostage-band1">TAKEN HOSTAGE</div>
          </div>
          <div className="rescue-button-wrapper">
            <Link to="/dashboard"> 
            <div className="rescue-button" >CHECK DASHBOARD TO RESCUE</div>
            </Link>
            {/* <div className="days-left">7 DAYS REMAINING</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomeHostage;
