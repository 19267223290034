import React, { useEffect, useContext, useState, Suspense } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { TwitterShareButton } from "react-share";

import { MainContext } from "../../components/context/context";
import Footer from "../../components/Footer/Footer";
import HallButton from "../../components/GotoHallButton/HallButton";

import "./WhitelistQuests.scss";

// Assets
import CardBottomBackground from "../../assets/media/images/cardBottomAsset.png";
// import MiddleCardBackground from "../../assets/media/images/lossCalculator.mp4";
// import RightCardBackground from "../../assets/media/images/ethSimulator.mp4";
import CampSceneStatic from "../../assets/media/images/campSceneStatic.png";
import LossTrackStatic from "../../assets/media/images/lossTrackStatic.png";
import KingStatic from "../../assets/media/images/kingStatic.png";

const CampSceneGifLazy = React.lazy(() =>
  import("./Lazy/LazyElements").then((module) => ({
    default: module.LazyLeftBackground,
  }))
);

const LossTrackGifLazy = React.lazy(() =>
  import("./Lazy/LazyElements").then((module) => ({
    default: module.LazyCenterBackground,
  }))
);

const KingGifLazy = React.lazy(() =>
  import("./Lazy/LazyElements").then((module) => ({
    default: module.LazyRightBackground,
  }))
);

// const BattleMissionGifLazy = React.lazy(() =>
//   import("./Lazy/LazyElements").then((module) => ({
//     default: module.LazyCenterBackground,
//   }))
// );

// const RescueMissionGifLazy = React.lazy(() =>
//   import("./Lazy/LazyElements").then((module) => ({
//     default: module.LazyRightBackground,
//   }))
// );

const WhitelistQuests = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const { setShowNavBar } = useContext(MainContext);
  const [isHovered, setIsHovered] = useState({
    left: false,
    center: false,
    right: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowNavBar(true);

    document.title = "Bear&Bull Game • Whitelist Quests";
    document.body.style.height = "auto";
    document.body.style.overflow = "scroll";
  }, []);

  const handleMouseEnter = (card) => {
    setIsHovered((prevState) => ({
      ...prevState,
      [card]: true,
    }));
  };

  const handleMouseLeave = (card) => {
    setIsHovered((prevState) => ({
      ...prevState,
      [card]: false,
    }));
  };

  return (
    <>
      <HallButton />
      <motion.div
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className="whitelist-quests"
      >
        <div className="whitelist-quests-texts-wrapper">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.25, ease: "easeInOut" }}
            className="whitelist-big-text"
          >
            ENTER A WHITELIST QUESTS
          </motion.div>
        </div>

        {/* Cards wrapper */}
        <div className="whitelist-quests-cards-wrapper">
          <motion.div
            initial={{ opacity: 0, x: isMobile ? 0 : 250 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.75, ease: "easeInOut" }}
            className="whitelist-quests-card"
            onMouseEnter={() => handleMouseEnter("left")}
            onMouseLeave={() => handleMouseLeave("left")}
          >
            <div className="leftcard-gradient" />

            {isHovered.left ? (
              <Suspense
                fallback={
                  <img
                    draggable={false}
                    className="leftcard-background"
                    src={CampSceneStatic}
                    alt=""
                  />
                }
              >
                <CampSceneGifLazy />
              </Suspense>
            ) : (
              <img
                draggable={false}
                className="leftcard-background"
                src={CampSceneStatic}
                alt=""
              />
            )}

            <div className="whitelist-quests-card-texts-wrapper">
              <div className="whitelist-quests-card-title">SOCIAL MISSION</div>
              <div className="whitelist-quests-card-description">
                Engage on Twitter for whitelist opportunities, giveaways, and
                more.
              </div>
            </div>
            <div className="whitelist-quests-card-button-wrapper">
              <img
                src={CardBottomBackground}
                className="card-bottom-background"
                alt=""
              />
              <div className="whitelist-quests-card-button">
                <TwitterShareButton
                  url="https://www.barbearians.xyz"
                  hashtags={["BEARS", "NFT"]}
                  title="I just joined the #BEARS #NFT community! 🐻"
                  placeholder="TWEET NOW"
                  className="twitter-share-button"
                />
                TWEET NOW
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: isMobile ? 1 : 0.75 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.7, delay: 0.25, ease: "easeInOut" }}
            className="whitelist-quests-card card-middle"
            onMouseEnter={() => handleMouseEnter("center")}
            onMouseLeave={() => handleMouseLeave("center")}
          >
            <div className="middlecard-gradient" />
            <img
              src={LossTrackStatic}
              className="middlecard-background"
              draggable={false}
              alt=""
            />

            {isHovered.center ? (
              <Suspense
                fallback={
                  <img
                    draggable={false}
                    className="leftcard-background"
                    src={LossTrackStatic}
                    alt=""
                  />
                }
              >
                <LossTrackGifLazy />
              </Suspense>
            ) : (
              <img
                draggable={false}
                className="leftcard-background"
                src={LossTrackStatic}
                alt=""
              />
            )}

            <div className="whitelist-quests-card-texts-wrapper">
              <div className="whitelist-quests-card-title">LOSS TRACKER</div>
              <div className="whitelist-quests-card-description">
                A daring quest competing weekly to uncover the most substantial
                NFT portfolio losses and secure your place on the whitelist.
              </div>
            </div>
            <div className="whitelist-quests-card-button-wrapper">
              <img
                src={CardBottomBackground}
                className="card-bottom-background middle"
                alt=""
              />
              <div
                onClick={() =>
                  window.open("https://barbearians-pl.pages.dev/", "_blank")
                }
                className="whitelist-quests-card-button"
              >
                CHECK OUT
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: isMobile ? 0 : -250 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.75, ease: "easeInOut" }}
            className="whitelist-quests-card"
            onMouseEnter={() => handleMouseEnter("right")}
            onMouseLeave={() => handleMouseLeave("right")}
          >
            <div className="rightcard-gradient" />

            <img
              draggable={false}
              src={KingStatic}
              className="rightcard-background"
            />

            {isHovered.right ? (
              <Suspense
                fallback={
                  <img
                    draggable={false}
                    src={KingStatic}
                    className="rightcard-background"
                    alt=""
                  />
                }
              >
                <KingGifLazy />
              </Suspense>
            ) : (
              <img
                draggable={false}
                src={KingStatic}
                className="rightcard-background"
                alt=""
              />
            )}
            <div className="whitelist-quests-card-texts-wrapper">
              <div className="whitelist-quests-card-title">ETH SIMULATOR</div>
              <div className="whitelist-quests-card-description right">
                Test your mettle by trading fake ETH, weekly racing against the
                clock to amass the greatest losses and secure your place on the
                whitelist.
              </div>
            </div>
            <div className="whitelist-quests-card-button-wrapper">
              <img
                src={CardBottomBackground}
                className="card-bottom-background"
                alt=""
              />
              <div
                onClick={() =>
                  window.open("https://sim.barbearians.xyz/", "_blank")
                }
                className="whitelist-quests-card-button"
              >
                CHECK OUT
              </div>
            </div>
          </motion.div>
        </div>
        {/* Cards wrapper */}
      </motion.div>
      <Footer />
    </>
  );
};

export default WhitelistQuests;
