import React, { useEffect, useState } from "react";
import { useBearsData } from "../../../../components/context/bearsContext";

const BattleMissionContainerRight = () => {
  const { bearsData, greenSixPercentage, redSixPercentage, hostagePrice } =
    useBearsData();

  // States
  const [sixPercentage, setSixPercentage] = useState("0%");

  const percentageHandler = (positivePercentage, negativePercentage) => {
    switch (true) {
      case greenSixPercentage >= 0.01:
        setSixPercentage(`+${positivePercentage}%`);
        break;
      case redSixPercentage >= 0.01:
        setSixPercentage(`${(negativePercentage - 6).toFixed(1)}%`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    percentageHandler(greenSixPercentage, redSixPercentage);
  }, [greenSixPercentage, redSixPercentage]);

  return (
    <div className="ongoingmission-container-right">
      <div className="stats-container">
        <div className="stats-title">STATS</div>
        <div className="bears-images">
          <div className="first-bearimg-wrapper">
            <img src={bearsData[0]?.bearImage} alt="" />
            <div className="bearcode-wrapper">#{bearsData[0]?.bearCode}</div>
          </div>
        </div>
        <div className="stats-text">
          <div className="stats-text-top">
            <div className="gold-rate-box">
              <div className="gold-rate-title">
                <p>GOLD RATE</p>
              </div>
              <div className="gold-rate-value">
                <p className="goldrate-value">28/DAY</p>
              </div>
            </div>
            <div className="total-golds-box">
              <div className="total-golds-title">TOTAL GOLDS</div>
              <div className="total-golds-value">729</div>
            </div>
          </div>

          <div className="stats-text-middle">
            <div className="missionstarted-box">
              <div className="missionstarted-title">MISSION STARTED</div>
              <div className="missionstarted-value">2 DAYS AGO</div>
            </div>
            <div className="time-remaining-box">
              <div className="time-remaining-title">TIME REMAINING</div>
              <div className="time-remaining-value">24H 12MINS</div>
            </div>
          </div>

          <div className="stats-text-bottom-battle">
            <div className="currentpercent-box">
              <div className="currentpercent-title">CURRENT PERCENT</div>
              <div className="currentpercent-value">{sixPercentage}</div>
            </div>
            <div className="hostageprice-box">
              <div className="hostageprice-title">HOSTAGE PRICE</div>
              <div className="hostageprice-value">{hostagePrice}</div>
            </div>
          </div>

          <div className="stats-button-wrapper">
            <div className="unstake-button">
              <p>UNSTAKE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattleMissionContainerRight;
