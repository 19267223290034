import React, { useState, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ReactPlayer from "react-player";
import { useAccount } from "wagmi";
import "react-toastify/dist/ReactToastify.css";

import { MainContext } from "./components/context/context";

import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { goerli, mainnet } from "wagmi/chains";

// Pages
import ProtectedRoute from "./ProtectedRoute";
import NavBar from "./components/NavBar/NavBar";
import Error from "./pages/ErrorPage/Error";
import Intro from "./pages/Intro/Intro";
import HallScene from "./pages/Intro/HallScene";
import Home from "./pages/Home/Home";
import Marketplace from "./pages/Marketplace/Marketplace";
import Missions from "./pages/Missions/Missions";
import OngoingMission from "./pages/Dashboard/OngoingMission/OngoingMission";
import WhitelistQuests from "./pages/WhitelistQuests/WhitelistQuests";
import UserProvider from "./auth/provider/user";
import Loader from "./components/Fallback/Loader";

// Assets
import MainSound from "./assets/sound/mainSong.mp3";
import { ToastContainer } from "react-toastify";
import { BearsDataProvider } from "./components/context/bearsContext";

// Lazy
const LazyAllowlistSale = lazy(() =>
  import("./pages/AllowlistSale/AllowlistSale")
);
const LazyAuctionCheck = lazy(() => import("./pages/Auction/AuctionCheck"));
const LazyPrivateSale = lazy(() => import("./pages/PrivateSale/PrivateSale"));
const LazyTheAllowlist = lazy(() =>
  import("./pages/TheAllowlist/TheAllowlist")
);
const LazyDashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const LazyClaimNFT = lazy(() => import("./pages/ClaimNFT/ClaimNFT"));
const LazySelectPage = lazy(() =>
  import("./pages/Dashboard/sections/SelectPage")
);
const LazyTerms = lazy(() => import("./pages/Terms/Terms"));
const LazyFAQ = lazy(() => import("./pages/Terms/FAQ"));
const LazyGraveyard = lazy(() => import("./pages/Graveyard/Graveyard"));
const LazyBattleMission = lazy(() =>
  import("./pages/BattleMission/BattleMission")
);
const LazyTrainingMission = lazy(() =>
  import("./pages/TrainingMission/TrainingMission")
);
const LazyRescueMission = lazy(() =>
  import("./pages/RescueMission/RescueMission")
);
const LazyWarGame = lazy(() => import("./pages/WarGame/WarGame"));
const LazyMarketplace = lazy(() => import("./pages/Marketplace/Marketplace"));

const chains = [goerli];
// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "5935b142975cc9c22faf48e3c5c8cda0" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  const { isConnected } = useAccount();
  const [isSoundEnabled, setIsSoundEnabled] = useState(false);
  const [showNavBar, setShowNavBar] = useState(false);
  const [playMainSong, setPlayMainSong] = useState(false);
  const [isIntroDone, setIsIntroDone] = useState(false);
  const [volume, setVolume] = useState(0.05);
  const location = useLocation();

  const data = {
    isSoundEnabled, setIsSoundEnabled,
    showNavBar, setShowNavBar,
    isIntroDone, setIsIntroDone,
    setPlayMainSong,
  }; // prettier-ignore

  return (
    <MainContext.Provider value={data}>
      <UserProvider>
        <BearsDataProvider>
          <WagmiConfig client={wagmiClient}>
            <ReactPlayer
              url={MainSound}
              playing={isSoundEnabled && playMainSong}
              loop={true}
              controls={false}
              muted={false}
              volume={volume}
              style={{
                display: "none",
              }}
            />
            <UserProvider>
              <ToastContainer limit={2} />
              <NavBar />
              <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                  <Route path="/" element={<Intro />} />
                  <Route path="hall" element={<HallScene />} />
                  <Route path="home" element={<Home />} />
                  {/* <Route path="story" element={<Story />} /> */}
                  <Route
                    path="marketplace"
                    element={
                      <ProtectedRoute>
                        <Suspense fallback={<Loader />}>
                          <LazyMarketplace />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="auction"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyAuctionCheck />
                      </Suspense>
                    }
                  />
                  <Route
                    path="private-sale"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyPrivateSale />
                      </Suspense>
                    }
                  />
                  <Route
                    path="theallowlist"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyTheAllowlist />
                      </Suspense>
                    }
                  />{" "}
                  {/* <Route path="whitepaper" element={<Whitepaper />} /> */}
                  <Route path="missions" element={<Missions />} />
                  <Route
                    path="dashboard"
                    element={
                      <ProtectedRoute isConnected={isConnected}>
                        <Suspense fallback={<Loader />}>
                          <LazyDashboard />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="allowlist-sale"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyAllowlistSale />
                      </Suspense>
                    }
                  />
                  <Route
                    path="claim-nft"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyClaimNFT />
                      </Suspense>
                    }
                  />
                  <Route
                    path="select-bears"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazySelectPage />
                      </Suspense>
                    }
                  />
                  <Route path="ongoing-mission" element={<OngoingMission />} />
                  <Route
                    path="battle-mission/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyBattleMission />
                      </Suspense>
                    }
                  />
                  <Route
                    path="training-mission/:trainingId"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyTrainingMission />
                      </Suspense>
                    }
                  />
                  <Route
                    path="rescue-mission/:rescueId"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyRescueMission />
                      </Suspense>
                    }
                  />
                  <Route
                    path="whitelist-quests"
                    element={<WhitelistQuests />}
                  />
                  <Route
                    path="graveyard"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyGraveyard />
                      </Suspense>
                    }
                  />
                  <Route
                    path="terms"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyTerms />
                      </Suspense>
                    }
                  />
                  <Route
                    path="faq"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyFAQ />
                      </Suspense>
                    }
                  />
                  <Route
                    path="wargames"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LazyWarGame />
                      </Suspense>
                    }
                  />
                  <Route path="/*" element={<Error />} />
                  {/* <Route path='/twitter-auth' element={<TwitterLoading />} /> */}
                </Routes>
              </AnimatePresence>
              <Web3Modal
                projectId="5935b142975cc9c22faf48e3c5c8cda0"
                ethereumClient={ethereumClient}
                themeColor="blackWhite"
                themeMode="dark"
              />
            </UserProvider>
          </WagmiConfig>
        </BearsDataProvider>
      </UserProvider>
    </MainContext.Provider>
  );
}

export default App;
