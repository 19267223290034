import React from "react";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

import "./Footer.scss";
import { transition } from "../../utils/animation";

// Assets
import { ReactComponent as FooterTop } from "../../assets/media/icons/FooterContainer.svg";
import FooterCoin from "../../assets/media/icons/FooterCoin.gif";
import TwitterPixelLogo from "../../assets/media/icons/TwitterPixel.png";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={transition}
      className="footer"
    >
      <div className="footer-top-wrapper">
        <div className="gold-effect" />
        <img draggable="false" src={FooterCoin} alt="" />
        <FooterTop className="footer-top" />
      </div>

      <Marquee className="footer-marquee" gradient={false}>
        <span>BEAR WITH US</span>
        <span>BEAR WITH US</span>
        <span>BEAR WITH US</span>
        <span>BEAR WITH US</span>
        <span>BEAR WITH US</span>
      </Marquee>
      <div className="footer-bottom">
        <div className="footer-bottom-left">
          <div className="footer-barberians">© BARBEARIANS 2022</div>
          <div onClick={() => navigate("/terms")} className="footer-terms">
            TERMS
          </div>
          <div onClick={() => navigate("/faq")} className="footer-faq">
            FAQ
          </div>
        </div>
        <div className="footer-bottom-right">
          <a
            href="https://twitter.com/BearBullGame"
            target={"_blank"}
            rel="noreferrer"
          >
            <div className="twitter-button">
              <img draggable="false" width={50} src={TwitterPixelLogo} alt="" />
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
