import React, { useEffect } from "react";
import { nanoid } from "nanoid";
import { gsap } from "gsap";

import "./Howitworks.scss";

// Assets
import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";

const marketplaceFaq = [
  {
    question: "What is the Marketplace?",
    answer:
      "The Marketplace is a place where you can buy and sell your bears. You can also buy and sell items that you can use to upgrade your bears.",
  },
  {
    question: "How do I buy a bear?",
    answer:
      "You can buy a bear by clicking on the bear you want to buy and clicking the buy button. You can also buy a bear by clicking on the bear you want to buy and clicking the buy button.",
  },
  {
    question: "How do I sell a bear?",
    answer:
      "You can sell a bear by clicking on the bear you want to sell and clicking the sell button. You can also sell a bear by clicking on the bear you want to sell and clicking the sell button.",
  },
  {
    question: "How do I buy an item?",
    answer:
      "You can buy an item by clicking on the item you want to buy and clicking the buy button. You can also buy an item by clicking on the item you want to buy and clicking the buy button.",
  },
  {
    question: "How do I sell an item?",
    answer:
      "You can sell an item by clicking on the item you want to sell and clicking the sell button. You can also sell an item by clicking on the item you want to sell and clicking the sell button.",
  },
];

const Howitworks = ({ setIsPopupOpen }) => {
  const handlePopupClose = () => {
    gsap
      .to([".howitworks-popup", ".howitworks-popup-back"], {
        opacity: 0,
        duration: 0.5,
        stagger: 0.1,
      })
      .then(() => {
        setIsPopupOpen(false);
      });
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.keyCode === 27) {
        handlePopupClose();
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  return (
    <>
      <div onClick={handlePopupClose} className="howitworks-popup-back" />
      <div className="howitworks-popup">
        <div className="howitworks-popup-header">
          <p className="marketplace-esc-text">ESC</p>
          <p>HOW IT WORKS?</p>
          <div
            onClick={handlePopupClose}
            className="close-marketplace-popup-btn"
          >
            <CloseIcon />
          </div>
        </div>

        <div className="marketplace-questions-wrapper">
          {marketplaceFaq.map((item, index) => (
            <div className="marketplace-question-wrapper" key={nanoid()}>
              <div className="marketplace-question">
                <p>{`${index + 1}. ${item.question}`}</p>
              </div>
              <div className="marketplace-answer">
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Howitworks;
