import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { useMediaQuery } from "react-responsive";
import { gsap } from "gsap";

import { MainContext } from "../../../components/context/context";
import { useBearsData } from "../../../components/context/bearsContext";

// Components
import BattleMissionContainerLeft from "./BattleMission/BattleMissionContainerLeft";
import BattleMissionContainerRight from "./BattleMission/BattleMissionContainerRight";
import BecomeHostage from "./components/BecomeHostage";
import InfoPopup from "../components/InfoPopup/InfoPopup";

import "./OngoingMission.scss";
// Assets
import PreviousIcon from "../../../assets/media/icons/previousIcon.png";
import goldCoin from "../../../assets/media/icons/BearCoinSmall.png";
import RescueMissionBanner from "../../../assets/media/images/rescueMissionCover.png";
import { ReactComponent as InfoIcon } from "../../../assets/media/icons/infoIconTwo.svg";
import { ReactComponent as ProgressLine } from "../../../assets/media/icons/AuctionLine.svg";
import { ReactComponent as Caution } from "../../../assets/media/icons/cautionPixel.svg";

const OngoingMission = () => {
  const tl = gsap.timeline();
  const navigate = useNavigate();
  const { setShowNavBar } = useContext(MainContext);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const {
    bearsData,
    isTrainingMission,
    isRescueMission,
    isBattleMission,
    greenSixPercentage,
    becomeHostagePopup,
    isInfoPopupOpen, setIsInfoPopupOpen,
  } = useBearsData(); // prettier-ignore

  // States
  const PLACEHOLDER_OPEN_PRICE = 1050;
  const [canUnstake, setCanUsestake] = useState(false);
  const [progress, setProgress] = useState(8.33);
  const [clipProgress, setClipProgress] = useState(91.67);
  const [showBattleWarning, setShowBattleWarning] = useState(false);
  const [warningPercentage, setWarningPercentage] = useState(0);

  useEffect(() => {
    setShowNavBar(true);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (progress >= 91.75) {
        setCanUsestake(true);
        setProgress(100);
        setClipProgress(0);
        clearInterval(intervalId);
      } else {
        setProgress((progress) => progress + 1);
        setClipProgress((clipProgress) => clipProgress - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [progress]);

  // If the greenSixPercentage is 3(finalValue is 6) or more, warning element will be shown
  useEffect(() => {
    if (greenSixPercentage >= 3) {
      setShowBattleWarning(true);
      setWarningPercentage(greenSixPercentage);
    } else {
      setShowBattleWarning(false);
    }
  }, [greenSixPercentage]);

  if (bearsData.length === 0) return <Navigate to="/dashboard" />;

  const popupHandler = () => {
    setIsInfoPopupOpen(true);

    setTimeout(() => {
      tl.to(".dashboard-info-popup-back", { opacity: 1 }).to(
        ".dashboard-info-popup",
        { opacity: 1 }
      );
    }, 1);
  };

  //this comes from backend
  const timestamp = 36600;

  function has48HoursPassed(timestamp) {
    const now = Date.now();
    const diffInMs = now - timestamp;
    const diffInHours = diffInMs / (1000 * 60 * 60);
    return diffInHours >= 48;
  }

  function has4WeeksPassed(timestamp) {
    const now = Date.now();
    const diffInMs = now - timestamp;
    const diffInWeeks = diffInMs / (1000 * 60 * 60 * 24 * 7);
    return diffInWeeks >= 4;
  }

  const has48Hours = has48HoursPassed(timestamp);
  const has4Weeks = has4WeeksPassed(timestamp);

  return (
    <>
      {isInfoPopupOpen && <InfoPopup />}
      {becomeHostagePopup && <BecomeHostage />}
      <div className="ongoing-mission">
        <div className="ongoingmission-nav-wrapper">
          <div
            onClick={() => {
              navigate("/dashboard");
            }}
            className="back-wrapper"
          >
            <img src={PreviousIcon} alt="" />
            <p>{!isMobile && "BACK TO"} DASHBOARD</p>
          </div>
          <div className="mission-type">
            {isTrainingMission && <p>TRAINING</p>}
            {isRescueMission && <p>RESCUE</p>}
            {isBattleMission && <p>BATTLE</p>}
          </div>
          <div onClick={popupHandler} className="info-popup-wrapper">
            <InfoIcon />
            <p>How it works</p>
          </div>
        </div>

        <div
          className={
            isRescueMission || isTrainingMission
              ? "ongoingmission-wrapper not-battle"
              : "ongoingmission-wrapper"
          }
        >
          {isBattleMission && showBattleWarning && (
            <div className="battlemission-warning">
              <div className="warning-left-wrapper">
                <div className="caution-icon">
                  <Caution width={75} />
                </div>
                <div className="warning-text">
                  <div className="warning-text-top">
                    Your bear is about to be held hostage!
                  </div>
                  <div className="warning-text-bottom">
                    ETH/USD has risen by {warningPercentage}% and your bear
                    might be held hostage if it goes above.
                  </div>
                </div>
              </div>
              <div className="warning-right-wrapper">
                {!canUnstake ? (
                  <div className="unstake-button disabled">
                    <p>UNSTAKE</p>
                  </div>
                ) : (
                  <div className="unstake-button ">
                    <p>UNSTAKE</p>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className={
              isBattleMission
                ? "ongoingmission-container battle-mission"
                : "ongoingmission-container"
            }
          >
            <div
              className={
                isRescueMission || isTrainingMission
                  ? "ongoingmission-container-left not-battle"
                  : "ongoingmission-container-left"
              }
            >
              {isRescueMission && (
                <div className="golds-info-wrapper">
                  <div className="goldday-wrapper">
                    <img src={goldCoin} alt="" />
                    <p>+48 GOLD / DAY</p>
                  </div>
                  <div className="goldcount-wrapper">
                    <img src={goldCoin} alt="" />
                    <p>124.00 GOLD</p>
                  </div>
                </div>
              )}
              {!isBattleMission && (isRescueMission || isTrainingMission) && (
                <>
                  <div className="image-wrapper">
                    <img
                      className="back-progress-image"
                      src={RescueMissionBanner}
                      alt=""
                    />
                  </div>
                  <div className="image-wrapper">
                    <img
                      className="front-progress-image"
                      style={{ clipPath: `inset(0 ${clipProgress}% 0 0)` }}
                      src={RescueMissionBanner}
                      alt=""
                    />
                  </div>
                </>
              )}
              {isBattleMission ? (
                <BattleMissionContainerLeft
                  PLACEHOLDER_OPEN_PRICE={PLACEHOLDER_OPEN_PRICE}
                  bearCode={bearsData[0].bearCode}
                />
              ) : (
                <div
                  className={
                    isTrainingMission
                      ? "progress-wrapper training"
                      : "progress-wrapper"
                  }
                >
                  <ProgressBar
                    completed={progress}
                    bgColor="#D9D9D9"
                    height="5px"
                    borderRadius="0"
                    isLabelVisible={false}
                    // animateOnRender={true}
                    baseBgColor="#3A3E4F"
                    labelColor="#e80909"
                  />
                  <div className="progress-labels-wrapper">
                    <div
                      className={
                        progress >= 0.0 && progress <= 24.98
                          ? "progress-label active"
                          : "progress-label"
                      }
                    >
                      <ProgressLine />
                      {isRescueMission && <p>RECONNAISSANCE</p>}
                      {isTrainingMission && <p>PLANNING</p>}
                    </div>
                    <div
                      className={
                        progress >= 24.99 && progress <= 41.66
                          ? "progress-label active"
                          : "progress-label"
                      }
                    >
                      <ProgressLine />
                      {isRescueMission && <p>INFILTRATION</p>}
                      {isTrainingMission && <p>BRIEFING</p>}
                    </div>
                    <div
                      className={
                        progress >= 41.67 && progress <= 58.32
                          ? "progress-label active"
                          : "progress-label"
                      }
                    >
                      <ProgressLine />
                      {isRescueMission && <p>RESCUE</p>}
                      {isTrainingMission && <p>REHEARSAL</p>}
                    </div>
                    <div
                      className={
                        progress >= 58.33 && progress <= 74.98
                          ? "progress-label active"
                          : "progress-label"
                      }
                    >
                      <ProgressLine />
                      {isRescueMission && (
                        <p>{!isMobile && "MEDICAL"} TREATMENT</p>
                      )}
                      {isTrainingMission && <p>EXECUTION</p>}
                    </div>
                    <div
                      className={
                        progress >= 74.99 && progress <= 91.75
                          ? "progress-label active"
                          : "progress-label"
                      }
                    >
                      <ProgressLine />
                      {isRescueMission && <p>EVACUATION</p>}
                      {isTrainingMission && <p>DEBRIEFING</p>}
                    </div>
                    <div
                      className={
                        progress >= 91.76
                          ? "progress-label active"
                          : "progress-label"
                      }
                    >
                      <ProgressLine />
                      {isRescueMission && (
                        <p>{!isMobile && "MISSION"} COMPLETE</p>
                      )}
                      {isTrainingMission && (
                        <p>{!isMobile && "MISSION"} COMPLETE</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isBattleMission ? (
              <BattleMissionContainerRight />
            ) : (
              <div className="ongoingmission-container-right">
                <div className="stats-container">
                  <div className="stats-title">STATS</div>
                  <div className="bears-images">
                    <div className="first-bearimg-wrapper">
                      <img src={bearsData[0]?.bearImage} alt="" />
                      <div className="bearcode-wrapper">
                        #{bearsData[0].bearCode}
                      </div>
                    </div>
                    {!isBattleMission && (
                      <div className="second-bearimg-wrapper">
                        <img src={bearsData[1]?.bearImage} alt="" />
                        <div className="bearcode-wrapper">
                          #{bearsData[1]?.bearCode}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="stats-text">
                    {isRescueMission && (
                      <div className="stats-text-top">
                        <div className="gold-rate-box">
                          <div className="gold-rate-title">
                            <p>GOLD RATE</p>
                          </div>
                          <div className="gold-rate-value">
                            <p className="goldrate-value">28/DAY</p>
                          </div>
                        </div>
                        <div className="total-golds-box">
                          <div className="total-golds-title">TOTAL GOLDS</div>
                          <div className="total-golds-value">729</div>
                        </div>
                      </div>
                    )}

                    <div
                      className={
                        isBattleMission
                          ? "stats-text-middle"
                          : "stats-text-bottom"
                      }
                    >
                      <div className="missionstarted-box">
                        <div className="missionstarted-title">
                          MISSION STARTED
                        </div>
                        <div className="missionstarted-value">2 DAYS AGO</div>
                      </div>
                      <div className="time-remaining-box">
                        <div className="time-remaining-title">
                          TIME REMAINING
                        </div>
                        <div className="time-remaining-value">24H 12MINS</div>
                      </div>
                    </div>
                  </div>

                  <div className="stats-button-wrapper">
                    {!canUnstake ? (
                      <div className="unstake-button disabled">
                        <p>UNSTAKE</p>
                      </div>
                    ) : (
                      <div className="unstake-button ">
                        <p>UNSTAKE</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OngoingMission;
