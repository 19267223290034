import React, { createContext, useState, useContext } from "react";

const BearsDataContext = createContext();

export function BearsDataProvider({ children }) {
  // Functional states
  const [swipedLeft, setSwipedLeft] = useState(false);
  const [swipedRight, setSwipedRight] = useState(false);
  const [tutorialPopupDone, setTutorialPopupDone] = useState(true);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);

  // States
  const [userBears, setUserBears] = useState([]);
  const [filteredSelectBears, setFilteredSelectBears] = useState([]);
  const [bearsData, setBearsData] = useState([]);
  const [isBattleDataLoading, setIsBattleDataLoading] = useState(false);

  // Percentage values
  const [greenSixPercentage, setGreenSixPercentage] = useState(0);
  const [redSixPercentage, setRedSixPercentage] = useState(0);
  const [hostagePrice, setHostagePrice] = useState(0);

  // Select states
  const [selectedNoobieTraining, setSelectedNoobieTraining] = useState(false);
  const [selectedBBTraining, setSelectedBBTraining] = useState(false);
  const [selectedRescueBear, setSelectedRescueBear] = useState(false);
  const [selectedBBRescue, setSelectedBBRescue] = useState(false);
  const [selectedBBBattle, setSelectedBBBattle] = useState(false);
  const [selectedBBWar, setSelectedBBWar] = useState(false);
  // Mission States
  const [isTrainingMission, setIsTrainingMission] = useState(false);
  const [isRescueMission, setIsRescueMission] = useState(false);
  const [isBattleMission, setIsBattleMission] = useState(false);
  const [isWarMission, setIsWarMission] = useState(false);

  // Bear state
  const [becomeHostagePopup, setBecomeHostagePopup] = useState(false);
  const [isPopupClosed, setIsPopupClosed] = useState(false);

  const data = {
    userBears, setUserBears,
    bearsData, setBearsData,
    selectedNoobieTraining, setSelectedNoobieTraining,
    selectedBBTraining, setSelectedBBTraining,
    filteredSelectBears, setFilteredSelectBears,
    selectedRescueBear, setSelectedRescueBear,
    selectedBBRescue, setSelectedBBRescue,
    isTrainingMission, setIsTrainingMission,
    isRescueMission, setIsRescueMission,
    isBattleMission, setIsBattleMission,
    selectedBBBattle, setSelectedBBBattle,
    greenSixPercentage, setGreenSixPercentage,
    redSixPercentage, setRedSixPercentage,
    hostagePrice, setHostagePrice,
    becomeHostagePopup, setBecomeHostagePopup,
    swipedLeft, setSwipedLeft,
    swipedRight, setSwipedRight,
    isPopupClosed, setIsPopupClosed,
    tutorialPopupDone, setTutorialPopupDone,
    isInfoPopupOpen, setIsInfoPopupOpen,
    isBattleDataLoading, setIsBattleDataLoading,
    selectedBBWar, setSelectedBBWar
  }; // prettier-ignore

  return (
    <BearsDataContext.Provider value={data}>
      {children}
    </BearsDataContext.Provider>
  );
}

export function useBearsData() {
  const context = useContext(BearsDataContext);
  if (!context) {
    throw new Error("useBearsData must be used within a BearsDataProvider");
  }
  return context;
}
