import React, { useState, useEffect } from "react";
import { nanoid } from "nanoid";
import { useBearsData } from "../../../../components/context/bearsContext";

// Assets
import { ReactComponent as ETHicon } from "../../../../assets/media/icons/ethPixel.svg";
import { ReactComponent as Line } from "../../../../assets/media/icons/AuctionLine.svg";
import hallFrame from "../../../../assets/media/images/hallFrame.png";
import demonBear from "../../../../assets/media/Intro/Hall/demonBearHover.gif";
import goldCoin from "../../../../assets/media/icons/BearCoinSmall.png";

const BattleMissionContainerLeft = ({
  PLACEHOLDER_OPEN_PRICE,
  bearCode = 111,
}) => {
  const {
    setGreenSixPercentage,
    setRedSixPercentage,
    setHostagePrice,
    setBecomeHostagePopup,
    isPopupClosed,
  } = useBearsData();

  // States

  const [valueArray, setValueArray] = useState([]);
  const [ethValue, setEthValue] = useState(PLACEHOLDER_OPEN_PRICE);
  const [greenAreaHeight, setGreenAreaHeight] = useState(0);
  const [redAreaHeight, setRedAreaHeight] = useState(0);
  const [bearMoveHeight, setBearMoveHeight] = useState(50);
  const [ethValueBoxMove, setEthValueBoxMove] = useState(50);

  useEffect(() => {
    const increasedValue = (openPrice) => {
      const values = [
        openPrice * 1.03,
        openPrice * 1.01,
        openPrice,
        openPrice * 0.95,
        openPrice * 0.97,
      ];

      setValueArray(
        values.map((value) => value.toFixed(2)).sort((a, b) => b - a)
      );
    };

    increasedValue(PLACEHOLDER_OPEN_PRICE);
  }, []);

  useEffect(() => {
    setHostagePrice(valueArray[0]);
  }, [valueArray]);

  const calculateBearMoveHeight = (greenPercentage, redPercentage) => {
    const greenHeight = (greenPercentage / 100) * 50;
    const redHeight = (redPercentage / 100) * 50;
    return greenHeight + redHeight;
  };

  const getEthValueBoxMove = (minValue, maxValue) => {
    const percentile = ((ethValue - minValue) / (maxValue - minValue)) * 100;
    if (percentile < 0 || percentile >= 100) return;
    setEthValueBoxMove(percentile);
  };

  const calculateHeights = (ethValue, firstData, lastData) => {
    const percentageIncrease = (1 / 6) * 100;

    const greenPercentage = Math.min(
      Math.max(
        ((ethValue - PLACEHOLDER_OPEN_PRICE) /
          (firstData - PLACEHOLDER_OPEN_PRICE)) *
          100,
        0
      ),
      100
    );
    const redPercentage = Math.min(
      Math.max(
        ((ethValue - lastData) / (PLACEHOLDER_OPEN_PRICE - lastData)) * 100,
        0
      ),
      100
    );

    setGreenAreaHeight(greenPercentage);
    setRedAreaHeight(100 - redPercentage);
    setBearMoveHeight(calculateBearMoveHeight(greenPercentage, redPercentage));

    setGreenSixPercentage((greenPercentage / percentageIncrease).toFixed(1));
    setRedSixPercentage((redPercentage / percentageIncrease).toFixed(1));
  };

  // Randomly change the ETH value(Simulating the price change)
  useEffect(() => {
    const intervalId = setInterval(() => {
      const random = Math.random();
      if (random < 0.01) {
        setEthValue((prevValue) => prevValue - 10);
      } else {
        setEthValue((prevValue) => prevValue + 10);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    calculateHeights(
      ethValue,
      valueArray[0],
      valueArray[valueArray.length - 1]
    );
    getEthValueBoxMove(valueArray[0], valueArray[valueArray.length - 1]);

    if (!isPopupClosed && ethValue >= valueArray[0]) {
      setBecomeHostagePopup(true);
    }
  }, [ethValue]);

  const getClassName = (index, length) => {
    if (index === 0) {
      return "value-wrapper green";
    } else if (index === length - 1) {
      return "value-wrapper red";
    } else {
      return "value-wrapper";
    }
  };

  const getLineClassName = (index, length) => {
    if (index === 0) {
      return "line green";
    } else if (index === length - 1) {
      return "line red";
    } else {
      return "line";
    }
  };

  const getRotatedBearClassName = (bearMoveHeight) => {
    if (bearMoveHeight >= 50.1) {
      return "demon-bear rotate-green";
    } else if (bearMoveHeight <= 49.9) {
      return "demon-bear rotate-red";
    } else {
      return "demon-bear";
    }
  };

  return (
    <>
      <div className="ongoingmission-container-left">
        <div className="golds-info-wrapper-battle">
          <div className="price-stats-wrapper">
            <div className="ethereumprice-wrapper">
              <ETHicon />
              <p>{ethValue}</p>
            </div>
            <div className="goldday-wrapper">
              <img src={goldCoin} alt="" />
              <p>+48 GOLD/DAY</p>
            </div>
            <div className="goldcount-wrapper">
              <img src={goldCoin} alt="" />
              <p>124.00 GOLD</p>
            </div>
          </div>
          <div className="volume-info-wrapper">
            <div className="volume-day">
              <p>
                <span>24H VOLUME:</span> 58M
              </p>
            </div>
            <div className="separate">•</div>
            <div className="highest-volume">
              <p>
                <span>H:</span> 1.221,873
              </p>
            </div>
            <div className="separate">•</div>
            <div className="lowest-volume">
              <p>
                <span>L:</span> 1.206,873
              </p>
            </div>
          </div>
        </div>
        <div className="exchange-wrapper">
          <div className="exchange-wrapper-left">
            <div
              style={{
                top: "12rem",
                transform: `translateY(${-bearMoveHeight}%)`,
              }}
              className="demon-bear-wrapper"
            >
              <div className="bear-code">BEAR #{bearCode}</div>
              <img className="frame-bear" src={hallFrame} alt="" />
              <img
                className={getRotatedBearClassName(bearMoveHeight)}
                src={demonBear}
                alt=""
              />
            </div>
            <div className="green-area-wrapper">
              <div
                style={{ height: `${greenAreaHeight}%` }}
                className="green-area"
              />
            </div>
            <div className="red-area-wrapper">
              <div
                style={{ height: `${redAreaHeight}%` }}
                className="red-area"
              />
            </div>
          </div>
          <div className="exchange-wrapper-right">
            <div
              style={{ transform: `translateY(${ethValueBoxMove * 17}%)` }}
              className="ethvalue-wrapper"
            >
              <div className="ethvalue-box">
                <p>{ethValue}</p>
              </div>
            </div>
            {valueArray.map((value, index) => (
              <div
                className={getClassName(index, valueArray.length)}
                key={nanoid()}
              >
                <Line className={getLineClassName(index, valueArray.length)} />
                <p>{value}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BattleMissionContainerLeft;
