import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import "./HallButton.scss";

const HallButton = ({ top = 7 }) => {
  const isTablet = useMediaQuery({ query: "(max-width: 950px)" });
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/hall");
  };

  return (
    <div
      style={{ top: `${top}rem` }}
      onClick={handleClick}
      className="hall-button"
    >
      {"< "}
      {!isTablet && "GO TO"} HALL
    </div>
  );
};

export default HallButton;
