import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./TheEcosystem.scss";
// Assets
import { ReactComponent as PlusIcon } from "../../../assets/media/icons/plusIcon.svg";
import LichBearBig from "../../../assets/media/GIF/lichBearHome.webm";
import ArmorBearBig from "../../../assets/media/GIF/armorBearHome.webm";
import MarketplaceBearBig from "../../../assets/media/GIF/marketplaceBearHome.webm";

gsap.registerPlugin(ScrollTrigger);

const TheEcosystem = () => {
  const isMediumDevice = useMediaQuery({
    query: "(max-width: 1550px)",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const mm = gsap.matchMedia();

    mm.add("(min-width: 480px)", () => {
      const sections = gsap.utils.toArray(".panel");

      gsap.to(sections, {
        xPercent:
          -100 * (sections.length - 1 + (isMediumDevice ? 0.1175 : 0.055)),
        ease: "none",
        scrollTrigger: {
          // markers: true,
          trigger: ".theecosystem",
          pin: true,
          scrub: 1,
          // snap: 1 / (sections.length - 1),
          start: "top top+=75",
          end: () => "+=" + document.querySelector(".theecosystem").offsetWidth,
        },
      });
    });
  }, []);

  return (
    <>
      <div className="theecosystem">
        <div className="ecosystem-title-wrapper">THE ECOSYSTEM</div>
        <div className="ecosystem-cards-wrapper">
          <div className="panel">
            <div className="top-side">
              <div className="left-side">
                <div className="information-box">
                  <div className="title-wrapper">WHITELIST QUESTS</div>
                  <div className="ecosystemsubtitle-wrapper">
                    Embark on epic bear market-themed quests to earn coveted
                    whitelist status for the project. Test your trading mettle
                    in our ETH simulator and earn whitelisted status for taking
                    the biggest lost. Connect your wallet and compete on our
                    real-time leaderboard showcasing the top NFT portfolio
                    losses to secure your place on the whitelist. Finally,
                    engage with us on social media to join the ranks of our
                    esteemed whitelist community.
                  </div>
                  <div className="goto-wrapper">
                    <div
                      onClick={() => navigate("/whitelist-quests")}
                      className="goto-button"
                    >
                      GO TO QUESTS
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div className="right-side-wrapper">
                  <PlusIcon className="plus-1" />
                  <PlusIcon className="plus-2" />
                  <PlusIcon className="plus-3" />
                  <PlusIcon className="plus-4" />
                  <video src={LichBearBig} playsInline loop muted autoPlay />
                </div>
              </div>
            </div>
          </div>
          <div className="panel">
            <div className="top-side">
              <div className="left-side">
                <div className="information-box">
                  <div className="title-wrapper">MISSIONS</div>
                  <div className="ecosystemsubtitle-wrapper">
                    Embark on daring battle missions to defeat bulls and claim
                    your hard-earned $GOLD, hone your skills through rigorous
                    training missions to transform your Noobie Bears into
                    fearsome Fighter Bears, and undertake high-stakes hostage
                    missions to rescue your Fighter Bears from the clutches of
                    enemy forces and emerge victorious!
                  </div>
                  <div className="goto-wrapper">
                    <div
                      onClick={() => navigate("/missions")}
                      className="goto-button"
                    >
                      GO TO MISSIONS
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div className="right-side-wrapper">
                  <PlusIcon className="plus-1" />
                  <PlusIcon className="plus-2" />
                  <PlusIcon className="plus-3" />
                  <PlusIcon className="plus-4" />
                  <video src={ArmorBearBig} playsInline loop muted autoPlay />
                </div>
              </div>
            </div>
          </div>
          <div className="panel">
            <div className="top-side">
              <div className="left-side">
                <div className="information-box">
                  <div className="title-wrapper">MARKETPLACE</div>
                  <div className="ecosystemsubtitle-wrapper">
                    Behold the Barbarian exclusive marketplace, where $GOLD
                    reigns supreme - here, you can purchase new Barbearians,
                    powerful upgrades, coveted whitelist spots, exclusive
                    merchandise, and even the legendary authority of the Bear
                    King. Send your Fighter Bears on daring missions to earn
                    valuable coins, fueling the growth of our ecosystem and
                    securing a sustainable future for all. With even more
                    incredible prizes on the horizon, the possibilities are
                    endless.
                  </div>
                  <div className="goto-wrapper">
                    <div
                      // onClick={() => navigate("/marketplace")}
                      className="goto-button disabled"
                    >
                      GO TO MARKETPLACE
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div className="right-side-wrapper">
                  <PlusIcon className="plus-1" />
                  <PlusIcon className="plus-2" />
                  <PlusIcon className="plus-3" />
                  <PlusIcon className="plus-4" />
                  <video
                    playsInline
                    loop
                    muted
                    autoPlay
                    src={MarketplaceBearBig}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheEcosystem;
